
import React from 'react';
import { StoryKeyword } from "@/types/story";
import { KeywordItem } from "@/components/keyword/KeywordItem";
import { EmptyState } from "@/components/ui/empty-state";
import { LoadingState } from "@/components/ui/loading-state";
import { FileText } from "lucide-react";
import { useTranslations } from "@/hooks/useTranslations";

interface SavedPhrasesListProps {
  keywords: StoryKeyword[];
  isLoading: boolean;
  targetLanguageCode: string;
  nativeLanguageCode: string;
  onRemove: (keywordId: string) => void;
  onSelect: (keyword: StoryKeyword) => void;
}

export const SavedPhrasesList: React.FC<SavedPhrasesListProps> = ({
  keywords,
  isLoading,
  targetLanguageCode,
  nativeLanguageCode,
  onRemove,
  onSelect
}) => {
  const { t } = useTranslations();

  if (isLoading) {
    return <LoadingState message="Loading your saved phrases..." />;
  }

  if (keywords.length === 0) {
    return (
      <EmptyState
        icon={<FileText className="h-12 w-12" />}
        title={t('phrases.empty')}
        description={t('phrases.save_phrases')}
      />
    );
  }

  return (
    <div className="space-y-2">
      {keywords.map((keyword) => (
        <KeywordItem
          key={keyword.id}
          keyword={keyword}
          targetLanguageCode={targetLanguageCode as any}
          nativeLanguageCode={nativeLanguageCode as any}
          onRemove={onRemove}
          onClick={() => onSelect(keyword)}
        />
      ))}
    </div>
  );
};
