
import React, { useState, useCallback } from "react";
import { useTranslations } from "@/hooks/useTranslations";
import { Button } from "@/components/ui/button";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faPlay, 
  faPause, 
  faShareFromSquare, 
  faCircleQuestion,
  faCircleArrowRight
} from '@fortawesome/free-solid-svg-icons';
import { FaceThinkingIcon, ArrowUpFromBracketIcon } from '@/lib/customIcons';

interface StoryHeaderProps {
  title?: string;
  onPlayAudio: () => void;
  onPauseAudio: () => void;
  isPlaying: boolean;
  onShare: () => void;
  isShortStory?: boolean;
  hasAudio?: boolean;
  explanation?: string;
  onAudioToggle?: (audioUrl?: string) => void;
}

export const StoryHeader = ({
  title,
  onPlayAudio,
  onPauseAudio,
  isPlaying,
  onShare,
  isShortStory,
  hasAudio,
  explanation,
  onAudioToggle,
}: StoryHeaderProps) => {
  const { t } = useTranslations();
  const iconWhite = "text-white"; // Define the iconWhite class

  const toggleAudio = useCallback(() => {
    // If onAudioToggle is provided, use it, otherwise use the old behavior
    if (onAudioToggle) {
      onAudioToggle();
    } else {
      isPlaying ? onPauseAudio() : onPlayAudio();
    }
  }, [isPlaying, onPauseAudio, onPlayAudio, onAudioToggle]);

  return (
    <div className="flex justify-between items-center mb-4 pt-4">
      <div className="space-x-2 flex items-center justify-between flex-1">
        {hasAudio !== false && (
          <Button
            variant="secondary" 
            size="default"
            onClick={toggleAudio}
            className="bg-[#1A1B30] hover:bg-[#2A2B40] text-white"
          >
            <FontAwesomeIcon 
              icon={isPlaying ? faPause : faPlay} 
              className={iconWhite} 
              size="sm" 
            />
          </Button>
        )}

        
        <div className="story-actions-right">
        {/* Explanation dialog */}
          <Sheet>
            <SheetTrigger asChild>
              <Button 
                variant="ghost" 
                size="icon"
                className="h-10 w-10"
              >
                <FaceThinkingIcon 
                  className="icon-secondary" 
                  size="lg" 
                />
                
              </Button>
            </SheetTrigger>
            <SheetContent side="bottom" className="h-fit max-h-[80vh] rounded-lg">
              <div className="space-y-4">
                <h3 className="text-lg font-semibold">{t('story.tab.explanation')}</h3>
                <p className="text-[#1A1B30]">{explanation}</p>
              </div>
            </SheetContent>
          </Sheet>
          
          {/* Share button */}
          <Button 
            variant="ghost" 
            size="icon"
            className="h-10 w-10"
            onClick={onShare}
          >
            <ArrowUpFromBracketIcon
              className="icon-secondary" 
              size="lg" 
            />
          </Button>
        </div>
      </div>
    </div>
  );
};
