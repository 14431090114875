
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { useTranslations, detectBrowserLanguage, languageCodeToName } from "@/hooks/useTranslations";
import { NativeLanguage, TargetLanguage, LanguageLevel } from "@/types/language";
import { targetLanguages, getLanguageCode } from "@/utils/languageUtils";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { NativeLanguageSelector } from "@/components/language/NativeLanguageSelector";
import { LevelSelector } from "@/components/language/LevelSelector";
import { TargetLanguageSelector } from "@/components/language/TargetLanguageSelector";

interface OnboardingProps {
  onComplete: () => void;
  initialStep?: number;
}

export const Onboarding = ({ onComplete, initialStep = 1 }: OnboardingProps) => {
  const navigate = useNavigate();
  const [selectedLanguage, setSelectedLanguage] = useState<NativeLanguage>("English");
  const [targetLanguage, setTargetLanguage] = useState<TargetLanguage>("German");
  const [selectedLevels, setSelectedLevels] = useState<LanguageLevel[]>(['A1', 'A2', 'B1', 'B2', 'C1', 'C2']);
  const [step, setStep] = useState(initialStep);
  
  // Get translation function with forced refresh capability
  const { t, forceRefresh } = useTranslations();

  useEffect(() => {
    const settings = localStorage.getItem('languageSettings');
    if (settings) {
      try {
        const { nativeLanguage, targetLanguage: savedTarget, levels } = JSON.parse(settings);
        if (nativeLanguage) setSelectedLanguage(nativeLanguage);
        if (savedTarget) setTargetLanguage(savedTarget);
        if (levels) setSelectedLevels(levels);
      } catch (error) {
        console.error("Error parsing language settings:", error);
        // Set default values if parsing fails
        const detectedLanguage = detectBrowserLanguage();
        setSelectedLanguage(detectedLanguage.name as NativeLanguage);
      }
    } else {
      // Try to detect browser language if no settings exist
      const detectedLanguage = detectBrowserLanguage();
      setSelectedLanguage(detectedLanguage.name as NativeLanguage);
    }
  }, []);

  const handleNativeLanguageChange = (newLanguage: NativeLanguage) => {
    setSelectedLanguage(newLanguage);
    
    // Get language code for the selected native language
    const languageCode = getLanguageCode(newLanguage).toLowerCase();
    
    const initialSettings = {
      nativeLanguage: newLanguage,
      nativeLanguageCode: languageCode,
      targetLanguage: targetLanguage,
      targetLanguageCode: getLanguageCode(targetLanguage),
      levels: selectedLevels
    };
    localStorage.setItem('languageSettings', JSON.stringify(initialSettings));
    
    // Force refresh of translations with the new language
    forceRefresh(languageCode as any);
  };

  const handleLevelToggle = (level: LanguageLevel) => {
    setSelectedLevels(prev => {
      const levelOrder: LanguageLevel[] = ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'];
      const levelIndex = levelOrder.indexOf(level);
      
      // Add the level and any required lower levels
      const newLevels = new Set<LanguageLevel>();
      for (let i = 0; i <= levelIndex; i++) {
        newLevels.add(levelOrder[i]);
      }
      return Array.from(newLevels);
    });
  };

  const handleContinue = () => {
    if (step === 1) {
      setStep(2);
      return;
    }
    
    if (step === 2) {
      setStep(3);
      return;
    }

    // Final step - save all settings and navigate
    const languageSettings = {
      nativeLanguage: selectedLanguage,
      nativeLanguageCode: getLanguageCode(selectedLanguage).toLowerCase(),
      targetLanguage: targetLanguage,
      targetLanguageCode: getLanguageCode(targetLanguage),
      levels: selectedLevels
    };
    localStorage.setItem('languageSettings', JSON.stringify(languageSettings));
    localStorage.setItem('onboardingComplete', 'true');
    onComplete(); // Signal completion to parent
    navigate('/app', { replace: true }); // Changed from '/' to '/app'
  };

  const handleBack = () => {
    if (step === 3) {
      setStep(2);
      return;
    }
    if (step === 2) {
      setStep(1);
    }
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className="onboarding-text">
            <h1 className="text-2xl font-bold text-[#1A1B30] mb-4">
              {t('language.select_native')}
            </h1>
            <p className="mb-6 text-[rgba(27,28,50,0.8)]">
              {t('onboarding.why_select_native')}
            </p>

            <div className="language-selector">
              <div className="relative">
                <NativeLanguageSelector
                  value={selectedLanguage}
                  onChange={handleNativeLanguageChange}
                  label=""
                  customClass="comic-select"
                />
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="onboarding-text">
            <h1 className="text-2xl font-bold text-[#1A1B30] mb-4">{t('onboarding.want_to_learn')}</h1>
            <div className="language-selector">
              <div className="relative">
                <TargetLanguageSelector
                  selectedLanguage={targetLanguage}
                  onSelectLanguage={setTargetLanguage}
                  label=""
                  disabledLanguage={selectedLanguage}
                  customClass="comic-select"
                />
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="onboarding-text">
            <h1 className="text-2xl font-bold text-[#1A1B30] mb-4">
              {t('language.my_level_of')} {t(`language.${targetLanguage.toLowerCase()}`)}
            </h1>
            <div className="language-selector">
              <LevelSelector
                selectedLevels={selectedLevels}
                onToggleLevel={handleLevelToggle}
                label=""
              />
            </div>
          </div>
        );
    }
  };

  const renderProgressDots = () => (
    <div className="progress-dots ">
      {[1, 2, 3].map((dotStep) => (
        <div
          key={dotStep}
          className={`progress-dots-single ${
            step === dotStep ? '' : 'progress-dots-active'
          }`}
        />
      ))}
    </div>
  );

  return (
    <div className="min-h-screen flex flex-col p-4 bg-white relative">
      {/* Back button in top left */}
      {step > 1 && (
        <Button
          onClick={handleBack}
          variant="outline"
          size="sm"
          className="absolute top-4 left-4"
        >
          <FontAwesomeIcon icon={faChevronLeft} className="h-5 w-5" />
        </Button>
      )}
      
      {/* Progress dots */}
      {renderProgressDots()}

      {/* Content container */}
      <div className="onboarding-container">
        <div className="w-full max-w-md space-y-8">
          
          {/* Step content */}
          {renderStep()}
          
          {/* Continue button */}
          <Button 
              onClick={handleContinue}
              variant="default"
              className="w-full py-6"
            >
              {step === 3 ? t('onboarding.start_learning') : t('common.continue')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
