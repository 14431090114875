
import React, { useEffect, useRef } from 'react';
import { LanguageBadge } from './LanguageBadge';
import { NativeLanguage, TargetLanguage } from '@/types/language';
import * as CustomIcons from '@/lib/customIcons';
import { Button } from '@/components/ui/button';

interface HeroSectionProps {
  t: (key: string) => string;
  nativeLanguage: NativeLanguage;
  targetLanguage: TargetLanguage;
  onNativeLanguageChange: (language: NativeLanguage) => void;
  onTargetLanguageChange: (language: TargetLanguage) => void;
  onStartLearning: () => void;
  getLanguageIcon: (language: TargetLanguage) => { iconFa?: any; iconComponent?: React.ReactNode };
  handleLanguageBadgeClick: (language: TargetLanguage) => void;
}

export const HeroSection = React.memo(({
  t,
  nativeLanguage,
  targetLanguage,
  onNativeLanguageChange,
  onTargetLanguageChange,
  onStartLearning,
  getLanguageIcon,
  handleLanguageBadgeClick
}: HeroSectionProps) => {
  const heroImagesRef = useRef<HTMLDivElement>(null);
  const smallCardRef = useRef<HTMLDivElement>(null);
  const largeCardRef = useRef<HTMLDivElement>(null);

  const handleVisitWebsite = () => {
    window.open('https://istorio.netlify.app/', '_blank');
  };

  useEffect(() => {
    const handleScroll = () => {
      if (heroImagesRef.current) {
        const scrollPosition = window.scrollY;
        // Apply different scroll speeds to the container and cards
        heroImagesRef.current.style.transform = `translateY(${scrollPosition * 0.08}px)`;
        
        if (smallCardRef.current) {
          smallCardRef.current.style.transform = `translateY(${scrollPosition * -0.05}px)`;
        }
        
        if (largeCardRef.current) {
          largeCardRef.current.style.transform = `translateY(${scrollPosition * 0.03}px)`;
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <section className="landing-hero container">
      <div className="floating-cards-container" ref={heroImagesRef}>
        <div className="hero-cards-wrapper">
          <div className="hero-image-small" ref={smallCardRef}>
            <img 
              src="/lovable-uploads/4dcc95ce-fcc6-496e-84c6-e02311afc05c.png" 
              alt="Small joke card with play button" 
              className="hero-card-image"
            />
          </div>
          
          <div className="hero-image-large" ref={largeCardRef}>
            <img 
              src="/lovable-uploads/e000da97-0157-4917-bf8b-1dc3f188a2f2.png" 
              alt="Joke card with translation" 
              className="hero-card-image"
            />
          </div>
        </div>
      </div>

      <div className="landing-hero-content">
        
        <div className="hero-title-container">
          <h1 className="landing-hero-title">
            Learn new&nbsp;   
          <span className="text-strikethrough">language</span> culture
            with humor
          </h1>
          
          <p className="landing-hero-subtitle">
            {t('landing.hero.subtitle')}
          </p>
        </div>
        
        <div className="language-badges-container">
          {['English', 'German', 'Spanish', 'French', 'Italian', 'Portuguese', 'Russian', 'Ukrainian', 'Dutch'].map((lang) => {
            const language = lang as TargetLanguage;
            const code = language === 'English' ? 'en' : 
                         language === 'German' ? 'de' : 
                         language === 'Spanish' ? 'es' : 
                         language === 'French' ? 'fr' : 
                         language === 'Italian' ? 'it' : 
                         language === 'Portuguese' ? 'pt' : 
                         language === 'Russian' ? 'ru' : 
                         language === 'Ukrainian' ? 'ua' : 
                         language === 'Dutch' ? 'nl' : '';
            
            // Get the translated language name
            const translatedLanguage = t(`language.${language.toLowerCase()}`);
            
            // Dynamically get the icon component based on country code
            const IconComponent = CustomIcons[code as keyof typeof CustomIcons];
            const iconComponent = IconComponent ? <IconComponent size="1em" /> : null;
            
            return (
              <LanguageBadge 
                key={lang}
                language={language}
                displayText={translatedLanguage}
                iconComponent={iconComponent}
                isActive={targetLanguage === language}
                onClick={() => handleLanguageBadgeClick(language)}
              />
            );
          })}
        </div>

        <div className="start-learning-button-container">
              <Button 
                onClick={handleVisitWebsite}
                className="start-learning-button"
                variant="default"
              >
                {t('onboarding.start_learning')}
              </Button>
        </div>
      </div>
    </section>
  );
});
