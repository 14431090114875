
import { useState, useEffect } from "react";
import { Sheet, SheetContent, SheetHeader, SheetTitle } from "./ui/sheet";
import { useTranslations } from "@/hooks/useTranslations";
import { Button } from "@/components/ui/button";
import { TargetLanguageSelector } from "./language/TargetLanguageSelector";
import { LevelSelector } from "./language/LevelSelector";
import { NativeLanguageSelector } from "./language/NativeLanguageSelector";
import { LanguageLevel, TargetLanguage, NativeLanguage } from "@/types/language";
import { getLanguageCode } from "@/utils/languageUtils";

interface LanguageSelectorProps {
  open: boolean;
  onClose: () => void;
  isOnboarding?: boolean;
  onComplete?: () => void;
}

export const LanguageSelector = ({ open, onClose, isOnboarding = false, onComplete }: LanguageSelectorProps) => {
  const { t, forceRefresh } = useTranslations();
  const [selectedTargetLang, setSelectedTargetLang] = useState<TargetLanguage>('German');
  const [selectedLevels, setSelectedLevels] = useState<LanguageLevel[]>(['A1', 'A2', 'B1', 'B2', 'C1', 'C2']); // Default to C2 which includes all lower levels
  const [nativeLanguage, setNativeLanguage] = useState<NativeLanguage>('English');

  useEffect(() => {
    const settings = localStorage.getItem('languageSettings');
    if (settings) {
      const { targetLanguage, nativeLanguage, levels } = JSON.parse(settings);
      setSelectedTargetLang(targetLanguage);
      setNativeLanguage(nativeLanguage);
      setSelectedLevels(levels.length > 0 ? levels : ['A1', 'A2', 'B1', 'B2', 'C1', 'C2']); // If no levels, default to C2
    }
  }, []);

  const handleNativeLanguageChange = (newLanguage: NativeLanguage) => {
    setNativeLanguage(newLanguage);
    
    // Get language code for the selected native language
    const languageCode = getLanguageCode(newLanguage).toLowerCase();
    
    const settings = {
      targetLanguage: selectedTargetLang,
      targetLanguageCode: getLanguageCode(selectedTargetLang),
      nativeLanguage: newLanguage,
      nativeLanguageCode: languageCode,
      levels: selectedLevels
    };
    localStorage.setItem('languageSettings', JSON.stringify(settings));
    
    // Force refresh of translations with the new language
    forceRefresh(languageCode as any);
  };

  const handleLevelToggle = (level: LanguageLevel) => {
    setSelectedLevels(prev => {
      const levelOrder: LanguageLevel[] = ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'];
      const levelIndex = levelOrder.indexOf(level);
      
      // Add the level and any required lower levels
      const newLevels = new Set<LanguageLevel>();
      for (let i = 0; i <= levelIndex; i++) {
        newLevels.add(levelOrder[i]);
      }
      return Array.from(newLevels);
    });
  };

  const handleApply = () => {
    const settings = {
      targetLanguage: selectedTargetLang,
      targetLanguageCode: getLanguageCode(selectedTargetLang),
      nativeLanguage,
      nativeLanguageCode: getLanguageCode(nativeLanguage),
      levels: selectedLevels
    };
    localStorage.setItem('languageSettings', JSON.stringify(settings));
    
    if (isOnboarding) {
      localStorage.setItem('onboardingComplete', 'true');
      onComplete?.();
    } else {
      onClose();
    }
    window.location.reload();
  };

  if (isOnboarding) {
    return (
      <div className="dialog min-h-screen bg-white flex flex-col items-center px-4 py-8 sm:px-6 lg:px-8">
        <div className="w-full max-w-md space-y-8">
          <div className="text-center">
            <h2 className="text-xl font-bold mb-4 text-[#1A1B30]">
              Choose your languages
            </h2>
          </div>

          <div className="space-y-8 mt-8">
            <div className="language-selector">
              <label className="text-[#1A1B30]">{t("language.selector.target.label")}</label>
              <TargetLanguageSelector
                selectedLanguage={selectedTargetLang}
                onSelectLanguage={setSelectedTargetLang}
                label=""
                customClass="comic-select"
              />
            </div>

            <div className="language-selector">
              <label className="text-[#1A1B30]">{t("language.selector.level.label")}</label>
              <LevelSelector
                selectedLevels={selectedLevels}
                onToggleLevel={handleLevelToggle}
                label=""
              />
            </div>

            <div className="language-selector">
              <label className="text-[#1A1B30]">{t("language.selector.native.label")}</label>
              <NativeLanguageSelector
                value={nativeLanguage}
                onChange={handleNativeLanguageChange}
                label=""
                customClass="comic-select"
              />
            </div>

            <Button 
              onClick={handleApply}
              variant="default"
              className="w-full py-6"
            >
              {t('button.apply')}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Sheet open={open} onOpenChange={onClose}>
      <SheetContent side="right" className="w-full sm:max-w-[540px] p-0 border-0">
        <div className="h-full flex flex-col">
          <SheetHeader className="p-6">
            <div className="flex items-center">
              <SheetTitle className="text-[28px] font-bold text-[#1A1B30]">
                {t("language.selector.title")}
              </SheetTitle>
            </div>
          </SheetHeader>
          
          <div className="flex-1 overflow-y-auto">
            <div className="p-6 space-y-8">
              <div className="language-selector">
                <label className="text-[#1A1B30]">{t("language.selector.native.label")}</label>
                <NativeLanguageSelector
                  value={nativeLanguage}
                  onChange={handleNativeLanguageChange}
                  label=""
                  customClass="comic-select"
                />
              </div>
              
              <div className="language-selector">
                <label className="text-[#1A1B30]">{t("language.selector.target.label")}</label>
                <TargetLanguageSelector
                  selectedLanguage={selectedTargetLang}
                  onSelectLanguage={setSelectedTargetLang}
                  label=""
                  disabledLanguage={nativeLanguage}
                  customClass="comic-select"
                />
              </div>
              
              <div className="language-selector">
                <label className="text-[#1A1B30]">{t("language.selector.level.label")}</label>
                <LevelSelector
                  selectedLevels={selectedLevels}
                  onToggleLevel={handleLevelToggle}
                  label=""
                />
              </div>
            </div>
          </div>

          <div className="p-6">
            <Button 
              onClick={handleApply}
              variant="default"
              className="w-full"
            >
              {t('button.apply')}
            </Button>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};
