import { useState, useMemo, useEffect } from "react";
import { Story } from "@/types/story";
import { useAudioPlayback } from "@/hooks/useAudioPlayback";
import { useAIAudioGeneration } from "@/hooks/useAIAudioGeneration";
import { StoryContent } from "./story/StoryContent";
import { StoryHeader } from "./story/StoryHeader";
import { StoryKeywords } from "./story/StoryKeywords";
import { StoryCardProps } from "@/types/storyComponents";

const gradients = [
  "linear-gradient(98deg, #8FEFFC 0%, #DEF6A8 100%)",
  "linear-gradient(98deg, #DEF6A8 0%, #FFC7EE 100%)",
  "linear-gradient(98deg, #FFC7EE 0%, #68D4F9 100%)"
];

const StoryCard = ({ story }: StoryCardProps) => {
  const [showTranslation, setShowTranslation] = useState(false);
  const [highlightedText, setHighlightedText] = useState<string>("");

  // Get the language settings to determine target and native languages
  const settings = localStorage.getItem('languageSettings');
  const { targetLanguageCode, nativeLanguageCode } = settings 
    ? JSON.parse(settings) 
    : { targetLanguageCode: 'en', nativeLanguageCode: 'en' };

  const randomGradient = useMemo(() => {
    const index = Math.abs(story.id.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0)) % gradients.length;
    return gradients[index];
  }, [story.id]);
  
  const isShortStory = story.type === 'short';

  // Audio playback hooks
  const { isPlaying, handleAudioToggle } = useAudioPlayback(
    story.contents[0]?.content || "",
    `story-${story.id}`,
    { onHighlightUpdate: setHighlightedText }
  );

  const {
    isGeneratingElevenLabsAudio,
    isElevenLabsPlaying,
    handleElevenLabsToggle
  } = useAIAudioGeneration(story.contents[0]?.content || "", {
    onHighlightUpdate: setHighlightedText
  });

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: 'Story',
        text: story.contents[0]?.content,
        url: window.location.href,
      }).catch((error) => console.log('Error sharing', error));
    }
  };

  const handleTranslationToggle = () => {
    setShowTranslation(!showTranslation);
  };

  // Only show cover image if it's a long story AND imageUrl exists
  const shouldShowCoverImage = !isShortStory && story.imageUrl;
  
  // Check if audio is available
  const hasAudio = Boolean(story.contents[0]?.audioUrl);

  return (
    <article 
      className={`story-card w-full overflow-hidden rounded-[8px] border break-inside-avoid mb-8 ${
        isShortStory 
          ? "border-[rgba(26,27,48,0.12)] shadow-[0px_4px_20px_0px_rgba(0,0,0,0.10)]" 
          : "border-[rgba(26,27,48,0.12)] bg-white shadow-[0px_4px_20px_0px_rgba(0,0,0,0.10)]"
      } short-story-card `}
      style={isShortStory ? { background: randomGradient } : undefined}
    >
      {shouldShowCoverImage && (
        <img
          src={story.imageUrl}
          alt=""
          className="w-full h-48 object-cover"
        />
      )}

      <div className="px-4">
        <StoryHeader 
          isShortStory={isShortStory}
          isPlaying={isPlaying}
          hasAudio={hasAudio}
          explanation={story.translations?.[0]?.explanation}
          onPlayAudio={() => handleAudioToggle(story.contents[0]?.audioUrl)}
          onPauseAudio={() => handleAudioToggle(story.contents[0]?.audioUrl)}
          onAudioToggle={() => handleAudioToggle(story.contents[0]?.audioUrl)}
          onShare={handleShare}
        />

        <StoryContent
          content={story.contents[0]?.content || ""}
          translation={story.translations?.[0]?.translation}
          isShortStory={isShortStory}
          showTranslation={showTranslation}
          highlightedText={highlightedText}
        />

        {story.keywords && story.keywords.length > 0 && (
          <StoryKeywords 
            keywords={story.keywords}
            targetLanguageCode={targetLanguageCode}
            nativeLanguageCode={nativeLanguageCode}
          />
        )}
      </div>
    </article>
  );
};

export default StoryCard;
