import { useState, useEffect, useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import StoryCard from "./StoryCard";
import { Story, LanguageCode, StoryLevel } from "@/types/story";
import { transformDbRowToStory } from "@/utils/storyTransforms";
import { supabase } from "@/integrations/supabase/client";
import { DbRow } from "@/types/database";
import { useTranslations } from "@/hooks/useTranslations";
import { Button } from "@/components/ui/button";
import FeedbackSection from "@/components/feedback/FeedbackSection";

interface LanguageSettings {
  targetLanguage: string;
  targetLanguageCode: LanguageCode;
  nativeLanguage: string;
  nativeLanguageCode: LanguageCode;
  levels: string[];
}

const STORIES_PER_PAGE = 10;

const StoriesCollection = () => {
  const [languageSettings, setLanguageSettings] = useState<LanguageSettings | null>(null);
  const [visibleStories, setVisibleStories] = useState<number>(STORIES_PER_PAGE);
  const { t } = useTranslations();

  useEffect(() => {
    const settings = localStorage.getItem('languageSettings');
    if (settings) {
      setLanguageSettings(JSON.parse(settings));
    }
  }, []);

  const handleLoadMore = useCallback(() => {
    setVisibleStories(prev => prev + STORIES_PER_PAGE);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 500) {
        handleLoadMore();
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleLoadMore]);

  const { data: stories, isLoading, error, refetch } = useQuery({
    queryKey: ["stories", languageSettings?.targetLanguageCode, languageSettings?.levels],
    queryFn: async () => {
      if (!languageSettings) return [];
      
      const getIncludedLevels = (selectedLevels: string[]): StoryLevel[] => {
        const levelOrder: StoryLevel[] = ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'];
        
        const selectedStoryLevels: StoryLevel[] = selectedLevels.map(level => level as StoryLevel);
      
        let highestIndex = -1;
        selectedStoryLevels.forEach(level => {
            const index = levelOrder.indexOf(level);
            if (index > highestIndex) {
                highestIndex = index;
            }
        });
      
        if (highestIndex === -1) {
            return [];
        }
      
        return levelOrder.slice(0, highestIndex + 1);
      };

      const allowedLevels = getIncludedLevels(languageSettings.levels);
      
      try {
        const today = new Date().toISOString().split('T')[0];
        
        const { data: storyData, error: storyError } = await supabase
          .from('stories')
          .select('*')
          .in('level', allowedLevels)
          .or(`publish_date.lte.${today},publish_date.is.null`);

        if (storyError) throw storyError;
        if (!storyData) return [];
        
        const filteredStories = storyData.filter(story => 
          story.content_json && 
          story.content_json[languageSettings.targetLanguageCode] && 
          story.content_json[languageSettings.targetLanguageCode].text !== ""
        );
        
        const transformedStories = await Promise.all(
          filteredStories.map(row => 
            transformDbRowToStory(
              row as DbRow,
              languageSettings.targetLanguageCode,
              languageSettings.nativeLanguageCode
            )
          )
        );

        return transformedStories;
      } catch (err) {
        console.error("Error fetching stories:", err);
        throw err;
      }
    },
    enabled: !!languageSettings,
    staleTime: 60000,
    refetchOnWindowFocus: true
  });

  useEffect(() => {
    const handleStoryImported = () => {
      console.log("Story imported, refreshing stories collection");
      refetch();
    };

    window.addEventListener('story-imported', handleStoryImported);
    return () => window.removeEventListener('story-imported', handleStoryImported);
  }, [refetch]);

  if (error) {
    return (
      <div className="loading-container">
        <p className="error-message">{t('story.error.loading')} {error.message}</p>
      </div>
    );
  }

  if (isLoading && !stories) {
    return (
      <div className="loading-container">
        <div className="loading-spinner" />
      </div>
    );
  }

  if (!languageSettings) {
    return (
      <div className="loading-container">
        <p className="empty-state">{t('stories.empty.language')}</p>
      </div>
    );
  }

  if (!stories || stories.length === 0) {
    return (
      <div className="loading-container">
        <p className="empty-state">{t('stories.empty.noStories')}</p>
      </div>
    );
  }

  const displayedStories = stories.slice(0, visibleStories);
  const hasMoreStories = stories.length > visibleStories;

  return (
    <div className="w-full max-w-[1400px] mx-auto">
      <div className="stories-container">
        {displayedStories.map((story) => (
          <StoryCard key={story.id} story={story} />
        ))}
      </div>
      {hasMoreStories && (
        <div className="mt-8 flex justify-center">
          <Button 
            onClick={handleLoadMore}
            className="bg-accent-bg text-primary hover:bg-opacity-80 transition-all"
          >
            {t('stories.loadMore') || 'Load more stories'}
          </Button>
        </div>
      )}
      <FeedbackSection source="stories_page" />
    </div>
  );
};

export default StoriesCollection;
