
import { ReactNode, useEffect, useState } from "react";
import { Onboarding } from "../pages/Onboarding";
import { useLocation, useNavigate } from "react-router-dom";

interface OnboardingGuardProps {
  children: ReactNode;
}

export const OnboardingGuard = ({ children }: OnboardingGuardProps) => {
  const [showOnboarding, setShowOnboarding] = useState<boolean | null>(null);
  const [onboardingStep, setOnboardingStep] = useState<number>(1);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const languageSettings = localStorage.getItem('languageSettings');
    const onboardingComplete = localStorage.getItem('onboardingComplete');
    const isOnboardingPath = location.pathname === '/onboarding';
    const fromLanding = location.state?.fromLanding;
    
    // Allow redoing onboarding if user explicitly navigates to /onboarding
    if (isOnboardingPath) {
      setShowOnboarding(true);
      return;
    }

    // Check if user has completed language settings
    const parsedSettings = languageSettings ? JSON.parse(languageSettings) : {};
    const hasLanguageSettings = parsedSettings.targetLanguage && parsedSettings.nativeLanguage;
    const hasLevelSettings = parsedSettings.levels && parsedSettings.levels.length > 0;

    // If user comes from landing page and has language settings but no level
    if (fromLanding && hasLanguageSettings && !hasLevelSettings) {
      setShowOnboarding(true);
      setOnboardingStep(3); // Go directly to level selection step
      return;
    }
    
    // If user comes from landing page with language settings
    if (fromLanding && hasLanguageSettings) {
      setShowOnboarding(true);
      setOnboardingStep(3); // Go directly to level selection step
      return;
    }

    // Regular onboarding check
    setShowOnboarding(!hasLanguageSettings || !hasLevelSettings || onboardingComplete !== 'true');
  }, [location.pathname, location.state, navigate]);

  if (showOnboarding === null) {
    return null;
  }

  if (showOnboarding) {
    return <Onboarding initialStep={onboardingStep} onComplete={() => setShowOnboarding(false)} />;
  }

  return <>{children}</>;
};
