
import React from 'react';
import LandingPage from '@/components/landing/LandingPage';

const Index = () => {
  return (
    <div className="w-full">
      <LandingPage />
    </div>
  );
};

export default Index;
