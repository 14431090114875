
import { Link, useLocation } from "react-router-dom";
import { useTranslations } from "@/hooks/useTranslations";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faMessage } from '@fortawesome/free-solid-svg-icons';

export const BottomNavigation = () => {
  const location = useLocation();
  const { t } = useTranslations();

  return (
    <nav className="fixed bottom-0 left-0 right-0 h-16 bg-white border-t border-gray-200 flex items-center justify-around px-4 bottom-navigation">
      <Link 
        to="/app" 
        className={`flex flex-col items-center gap-1 ${
          location.pathname === '/app' || location.pathname === '/' ? 'bottom-nav-active' : 'text-[#8E9196]'
        }`}
      >
        <FontAwesomeIcon icon={faBook} className="w-6 h-6" />
        <span className="text-xs">{t("nav.stories")}</span>
      </Link>
      
      <Link 
        to="/phrases" 
        className={`flex flex-col items-center gap-1 ${
          location.pathname === '/phrases' ? 'bottom-nav-active' : 'text-[#8E9196]'
        }`}
      >
        <FontAwesomeIcon icon={faMessage} className="w-6 h-6" />
        <span className="text-xs">{t("nav.phrases")}</span>
      </Link>
    </nav>
  );
};
