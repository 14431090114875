
import { useState, useEffect } from 'react';
import { supabase } from "@/integrations/supabase/client";
import { StoryKeyword } from "@/types/story";
import { toast } from "sonner";

export const useSavedKeywords = (targetLanguageCode: string, nativeLanguageCode: string) => {
  const [savedKeywords, setSavedKeywords] = useState<StoryKeyword[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadSavedKeywords = async () => {
      try {
        setIsLoading(true);
        // First get the saved keyword IDs from localStorage
        const saved = localStorage.getItem('savedKeywords');
        const savedKeywordsList = saved ? JSON.parse(saved) : [];
        
        if (savedKeywordsList.length === 0) {
          setSavedKeywords([]);
          setIsLoading(false);
          return;
        }

        // Then fetch the full keyword data from Supabase
        const keywordIds = savedKeywordsList.map((k: StoryKeyword) => k.id);
        const { data, error } = await supabase
          .from('keywords')
          .select('*')
          .in('keyword_id', keywordIds);

        if (error) {
          console.error('Error fetching keywords:', error);
          toast.error('Failed to load saved keywords');
          return;
        }

        console.log('Fetched keyword data:', data);

        if (data) {
          // Map the data to match our StoryKeyword type
          const mappedKeywords = data.map(kw => {
            const audioFieldName = `keyword_audio_${targetLanguageCode}`;
            console.log('Processing keyword:', {
              keywordId: kw.keyword_id,
              targetTranslation: kw.translations_json?.[targetLanguageCode],
              audioUrl: kw.audio_json?.[targetLanguageCode]
            });

            const targetTranslation = kw.translations_json?.[targetLanguageCode];
            const nativeTranslation = kw.translations_json?.[nativeLanguageCode];
            const fallbackTranslation = kw.translations_json?.['en'] || "Unknown";

            return {
              id: kw.keyword_id,
              word: targetTranslation || fallbackTranslation,
              translations: [
                {
                  language: targetLanguageCode as any,
                  translation: targetTranslation || fallbackTranslation
                },
                {
                  language: nativeLanguageCode as any,
                  translation: nativeTranslation || fallbackTranslation
                }
              ],
              [audioFieldName]: kw.audio_json?.[targetLanguageCode],
              phraseLevel: kw.word_level as any,
              phraseType: kw.word_type,
              associated_stories: kw.associated_stories
            };
          });

          // Sort keywords based on their order in savedKeywordsList (newest first)
          const sortedKeywords = mappedKeywords.sort((a, b) => {
            const aIndex = savedKeywordsList.findIndex((k: StoryKeyword) => k.id === a.id);
            const bIndex = savedKeywordsList.findIndex((k: StoryKeyword) => k.id === b.id);
            return bIndex - aIndex; // Reverse order (newest first)
          });

          console.log('Mapped keywords:', sortedKeywords);
          setSavedKeywords(sortedKeywords);
        }
      } catch (err) {
        console.error('Error processing keywords:', err);
        toast.error('Failed to process saved keywords');
      } finally {
        setIsLoading(false);
      }
    };

    loadSavedKeywords();
  }, [targetLanguageCode, nativeLanguageCode]);

  const handleMarkAsLearnt = (keywordId: string) => {
    // Get current saved keywords from localStorage
    const saved = localStorage.getItem('savedKeywords');
    const savedKeywordsList = saved ? JSON.parse(saved) : [];
    
    // Remove the keyword
    const updatedKeywords = savedKeywordsList.filter((k: StoryKeyword) => k.id !== keywordId);
    
    // Update localStorage
    localStorage.setItem('savedKeywords', JSON.stringify(updatedKeywords));
    
    // Update state
    setSavedKeywords(prev => prev.filter(k => k.id !== keywordId));
  };

  return {
    savedKeywords,
    isLoading,
    handleMarkAsLearnt
  };
};
