
import React from 'react';
import { useTranslations } from '@/hooks/useTranslations';
import FeedbackForm from './FeedbackForm';

interface FeedbackSectionProps {
  source: string;
  className?: string;
}

const FeedbackSection: React.FC<FeedbackSectionProps> = ({ source, className = '' }) => {
  const { t, isLoading, currentLanguage } = useTranslations();

  if (isLoading) {
    return <div className="p-6 text-center">Loading translations...</div>;
  }

  return (
    <section className={`feedback-section container ${className}`}>
      <div className="mx-auto">
        <div className="feedback-form-container">
          <div className="feedback-header">
            <h2 className="section_title">
              {t('feedback.title')}
              <span className="feedback-rocket" role="img" aria-label="rocket">🚀</span>
            </h2>
            <p className="landing-feature-description">
              {t('feedback.description')}
            </p>
          </div>
          <FeedbackForm source={source} key={`feedback-form-${currentLanguage}`} />
        </div>
      </div>
    </section>
  );
};

export default FeedbackSection;
