
import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { X } from "lucide-react";
import { StoryKeyword } from "@/types/story";
import { supabase } from "@/integrations/supabase/client";
import { useAudioPlayback } from "@/hooks/useAudioPlayback";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';

interface PhraseFlashcardDialogProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  keyword: StoryKeyword | null;
  targetLanguageCode: string;
  nativeLanguageCode: string;
  storyContext?: {
    content: string;
    imageUrl?: string;
    type: 'long' | 'short';
    gradient?: string;
  };
  autoplayAudio?: boolean;
  inPracticeMode?: boolean;
}

export const PhraseFlashcardDialog: React.FC<PhraseFlashcardDialogProps> = ({
  isOpen,
  onOpenChange,
  keyword,
  targetLanguageCode,
  nativeLanguageCode,
  storyContext,
  autoplayAudio = true,
  inPracticeMode = false
}) => {
  const [storyContexts, setStoryContexts] = useState<{
    content: string;
    imageUrl?: string;
    type: 'long' | 'short';
    gradient?: string;
    storyId?: string;
  }[]>([]);

  // Convert single storyContext to array format
  useEffect(() => {
    if (storyContext) {
      setStoryContexts([storyContext]);
    } else if (keyword && keyword.associated_stories && keyword.associated_stories.length > 0) {
      loadStoryContexts(keyword);
    } else {
      setStoryContexts([]);
    }
  }, [storyContext, keyword]);

  // Load story contexts for a keyword from associated stories
  const loadStoryContexts = async (keyword: StoryKeyword) => {
    try {
      const contexts: {
        content: string;
        imageUrl?: string;
        type: 'long' | 'short';
        gradient?: string;
        storyId?: string;
      }[] = [];

      if (!keyword.associated_stories || keyword.associated_stories.length === 0) {
        setStoryContexts([]);
        return;
      }

      // Get the language settings
      const { targetLanguageCode } = localStorage.getItem('languageSettings')
        ? JSON.parse(localStorage.getItem('languageSettings') || '{}')
        : { targetLanguageCode: 'en' };
      
      // Fetch all associated stories at once
      const { data: storiesData, error: storiesError } = await supabase
        .from('stories')
        .select('id, type, image_url, gradient, content_json')
        .in('id', keyword.associated_stories);
        
      if (storiesError) {
        console.error(`Error fetching stories for keyword ${keyword.id}:`, storiesError);
        setStoryContexts([]);
        return;
      }
      
      if (!storiesData || storiesData.length === 0) {
        console.error(`No story data found for keyword ${keyword.id}`);
        setStoryContexts([]);
        return;
      }
      
      // Process each story to extract relevant context
      for (const storyData of storiesData) {
        // Extract content for the target language
        const contentObj = storyData.content_json?.[targetLanguageCode];
        const content = typeof contentObj === 'object' ? contentObj.text : contentObj || '';
        
        // For long stories, extract just a sentence containing the keyword
        let contextContent = content;
        if (storyData.type === 'long' && content) {
          const word = keyword.word;
          const sentences = content.split(/(?<=[.!?])\s+/);
          const sentenceWithKeyword = sentences.find(s => 
            s.toLowerCase().includes(word.toLowerCase())
          );
          
          if (sentenceWithKeyword) {
            contextContent = sentenceWithKeyword;
          }
        }
        
        const gradients = [
          "linear-gradient(98deg, #8FEFFC 0%, #DEF6A8 100%)",
          "linear-gradient(98deg, #DEF6A8 0%, #FFC7EE 100%)",
          "linear-gradient(98deg, #FFC7EE 0%, #68D4F9 100%)"
        ];
        
        const getRandomGradient = (id: string) => {
          if (!id) return gradients[0];
          const index = Math.abs(id.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0)) % gradients.length;
          return gradients[index];
        };
        
        contexts.push({
          content: contextContent,
          imageUrl: storyData.image_url,
          type: storyData.type,
          gradient: storyData.gradient || getRandomGradient(storyData.id),
          storyId: storyData.id
        });
      }
      
      setStoryContexts(contexts);
    } catch (err) {
      console.error('Error loading story contexts:', err);
      setStoryContexts([]);
    }
  };

  // Get translation for the current keyword
  const getTranslation = (languageCode: string) => {
    if (!keyword) return "";
    const translations = keyword.translations || [];
    return translations.find(t => t.language === languageCode)?.translation || keyword?.word || "";
  };

  const targetWord = getTranslation(targetLanguageCode);
  const nativeWord = getTranslation(nativeLanguageCode);
  
  // Get audio URL for the keyword
  const getKeywordAudioUrl = () => {
    if (!keyword) return "";
    const audioFieldName = `keyword_audio_${targetLanguageCode}`;
    return keyword[audioFieldName] || keyword.audioUrl || "";
  };
  
  // Setup audio playback
  const { isPlaying, handleAudioToggle } = useAudioPlayback(
    targetWord,
    `flashcard-${keyword?.id || "unknown"}-${targetLanguageCode}`
  );
  
  // Handle playing audio
  const handlePlayAudio = async (e: React.MouseEvent) => {
    e.stopPropagation();
    const audioUrl = getKeywordAudioUrl();
    if (audioUrl) {
      await handleAudioToggle(audioUrl);
    } else {
      console.error("No audio available for this word");
    }
  };

  // Autoplay audio when the dialog opens
  useEffect(() => {
    if (isOpen && keyword && autoplayAudio) {
      const audioUrl = getKeywordAudioUrl();
      if (audioUrl) {
        // Small delay to ensure the dialog is fully loaded
        const timer = setTimeout(() => {
          handleAudioToggle(audioUrl);
        }, 300);
        return () => clearTimeout(timer);
      }
    }
  }, [isOpen, keyword, autoplayAudio]);

  // Define iconWhite class similar to StoryHeader
  const iconWhite = "text-white";

  // Don't render the dialog content in practice mode - it will be rendered by the parent
  if (inPracticeMode) {
    return (
      <div className="p-6">
        <div className="flex justify-between items-center mb-4">
          <div className="space-x-2 flex items-center">
            {getKeywordAudioUrl() && (
              <Button
                variant="secondary" 
                size="default"
                onClick={handlePlayAudio}
                className="bg-[#1A1B30] hover:bg-[#2A2B40] text-white mx-auto"
              >
                <FontAwesomeIcon 
                  icon={isPlaying ? faPause : faPlay} 
                  className={iconWhite} 
                  size="sm" 
                />
              </Button>
            )}
          </div>
        </div>
        
        <div className="text-center mb-4 relative">
          <h2 className="text-2xl font-semibold mb-1">{targetWord}</h2>
          <p className="text-green-600">{nativeWord}</p>
        </div>
        
        {storyContexts.map((story, index) => (
          <div 
            key={story.storyId || index}
            className="mt-4"
            style={story.type === 'short' && story.gradient ? { background: story.gradient } : {}}
          >
            {story.imageUrl && (
              <div className="w-full h-40 overflow-hidden rounded-lg mb-4">
                <img 
                  src={story.imageUrl} 
                  alt="" 
                  className="w-full h-full object-cover"
                />
              </div>
            )}
            <p className={`text-lg ${story.type === 'short' ? 'text-black' : 'text-gray-800'}`}>
              {story.content}
            </p>
          </div>
        ))}
      </div>
    );
  }

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-md p-0 gap-0 overflow-hidden bg-white">
        <div className="relative">
          <Button
            variant="ghost" 
            size="icon"
            className="absolute right-2 top-2 h-8 w-8 z-10"
            onClick={() => onOpenChange(false)}
          >
            <X className="h-5 w-5" />
          </Button>
          
          {keyword && (
            <div className="p-6">
              <div className="flex justify-between items-center mb-4">
                <div className="space-x-2 flex items-center">
                  {getKeywordAudioUrl() && (
                    <Button
                      variant="secondary" 
                      size="default"
                      onClick={handlePlayAudio}
                      className="bg-[#1A1B30] hover:bg-[#2A2B40] text-white mx-auto"
                    >
                      <FontAwesomeIcon 
                        icon={isPlaying ? faPause : faPlay} 
                        className={iconWhite} 
                        size="sm" 
                      />
                    </Button>
                  )}
                </div>
              </div>
              
              <div className="text-center mb-4 relative">
                <h2 className="text-2xl font-semibold mb-1">{targetWord}</h2>
                <p className="text-green-600">{nativeWord}</p>
              </div>
              
              {storyContexts.map((story, index) => (
                <div 
                  key={story.storyId || index}
                  className="mt-4"
                  style={story.type === 'short' && story.gradient ? { background: story.gradient } : {}}
                >
                  {story.imageUrl && (
                    <div className="w-full h-40 overflow-hidden rounded-lg mb-4">
                      <img 
                        src={story.imageUrl} 
                        alt="" 
                        className="w-full h-full object-cover"
                      />
                    </div>
                  )}
                  <p className={`text-lg ${story.type === 'short' ? 'text-black' : 'text-gray-800'}`}>
                    {story.content}
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
