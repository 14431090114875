
import { Story, LanguageCode, StoryContent, StoryTranslation, StoryKeyword, StoryLevel } from "@/types/story";
import { DbRow, DbKeyword } from "@/types/database";
import { supabase } from "@/integrations/supabase/client";

export const transformDbRowToStory = async (
  story: DbRow,
  targetLanguageCode: LanguageCode,
  nativeLanguageCode: LanguageCode
): Promise<Story> => {
  console.log("Transforming story:", { 
    storyId: story.id,
    targetLanguage: targetLanguageCode,
    nativeLanguage: nativeLanguageCode
  });

  // Get content and audio from the consolidated JSON structure
  const contentObj = story.content_json?.[targetLanguageCode];
  const content = typeof contentObj === 'object' ? contentObj.text : contentObj || "";
  const audioUrl = story.audio_json?.[targetLanguageCode];
  
  // Get translation and explanation from the consolidated JSON structure
  const translation = story.translations_json?.[nativeLanguageCode] || 
    (story.content_json?.[nativeLanguageCode]?.text || "");
  
  // Get explanation from the explanations_json field
  const explanation = story.explanations_json?.[nativeLanguageCode];

  // Fetch keywords data from keywords table
  let keywords: StoryKeyword[] = [];
  if (story.keywords && story.keywords.length > 0) {
    const { data: keywordsData, error } = await supabase
      .from('keywords')
      .select('*')
      .in('keyword_id', story.keywords);

    if (keywordsData && keywordsData.length > 0) {
      keywords = keywordsData.map((kw) => {
        const targetTranslation = kw.translations_json?.[targetLanguageCode];
        const nativeTranslation = kw.translations_json?.[nativeLanguageCode];
        const targetAudioUrl = kw.audio_json?.[targetLanguageCode];
        const audioFieldName = `keyword_audio_${targetLanguageCode}`;
        const fallbackTranslation = kw.translations_json?.['en'] || "Unknown";
        
        return {
          id: kw.keyword_id,
          word: targetTranslation || fallbackTranslation,
          [audioFieldName]: targetAudioUrl,
          translations: [
            {
              language: targetLanguageCode,
              translation: targetTranslation || fallbackTranslation
            },
            {
              language: nativeLanguageCode,
              translation: nativeTranslation || fallbackTranslation
            }
          ],
          phraseLevel: kw.word_level as StoryLevel,
          phraseType: kw.word_type,
          associated_stories: kw.associated_stories
        };
      });
    }
  }

  return {
    id: story.id,
    type: story.type,
    level: story.level,
    readingTime: story.reading_time || undefined,
    imageUrl: story.image_url || undefined,
    gradient: story.gradient || undefined,
    targetLanguage: targetLanguageCode,
    contents: [{
      language: targetLanguageCode,
      content,
      audioUrl: audioUrl || undefined
    }],
    translations: [{
      language: nativeLanguageCode,
      translation,
      explanation: explanation || undefined
    }],
    keywords,
    createdAt: story.created_at,
    updatedAt: story.updated_at
  };
};
