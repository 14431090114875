
import { useState, useRef, useEffect } from "react";

interface AudioPlaybackOptions {
  onHighlightUpdate?: (highlightedText: string) => void;
}

// Create a global store for audio elements to prevent duplicates
const audioElements: {[key: string]: HTMLAudioElement} = {};

export const useAudioPlayback = (text: string, audioKey: string = 'default', options?: AudioPlaybackOptions) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  // Clean up on unmount
  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        
        // Remove only this specific audio element from the global store
        if (audioKey in audioElements) {
          delete audioElements[audioKey];
        }
        
        audioRef.current = null;
      }
    };
  }, [audioKey]);

  const handleAudioToggle = async (audioUrl: string | undefined) => {
    if (!audioUrl) {
      console.error('No audio URL available');
      return;
    }

    // If already playing, pause it
    if (isPlaying && audioRef.current) {
      audioRef.current.pause();
      setIsPlaying(false);
      return;
    }

    try {
      // First, pause all other playing audio
      Object.entries(audioElements).forEach(([key, audio]) => {
        if (key !== audioKey && audio) {
          audio.pause();
          audio.currentTime = 0;
        }
      });
      
      // Setup the audio element if it doesn't exist yet
      if (!audioRef.current) {
        // Check if we already have this audio element in our global store
        if (audioKey in audioElements) {
          audioRef.current = audioElements[audioKey];
        } else {
          // Create a new audio element
          audioRef.current = new Audio(audioUrl);
          audioElements[audioKey] = audioRef.current;
          
          // Setup event listeners
          audioRef.current.addEventListener('ended', () => {
            setIsPlaying(false);
            options?.onHighlightUpdate?.("");
          });
          
          audioRef.current.addEventListener('error', (e) => {
            console.error('Audio error:', e);
            setIsPlaying(false);
            options?.onHighlightUpdate?.("");
          });
          
          audioRef.current.addEventListener('timeupdate', () => {
            if (audioRef.current) {
              const currentTime = audioRef.current.currentTime;
              const duration = audioRef.current.duration;
              const words = text.split(' ');
              const wordIndex = Math.floor((currentTime / duration) * words.length);
              options?.onHighlightUpdate?.(words.slice(0, wordIndex + 1).join(' '));
            }
          });

          audioRef.current.addEventListener('pause', () => {
            setIsPlaying(false);
          });

          audioRef.current.addEventListener('play', () => {
            setIsPlaying(true);
          });

          // For mobile devices: preload audio
          audioRef.current.load();
        }
      } else {
        // Make sure we're using the latest URL
        if (audioRef.current.src !== audioUrl) {
          audioRef.current.src = audioUrl;
          audioRef.current.load();
        }
      }

      try {
        // Play audio
        const playPromise = audioRef.current.play();
        if (playPromise !== undefined) {
          await playPromise;
          setIsPlaying(true);
        }
      } catch (error) {
        console.error('Error playing audio:', error);
        setIsPlaying(false);
      }
    } catch (error) {
      console.error('Error setting up audio:', error);
      setIsPlaying(false);
      options?.onHighlightUpdate?.("");
    }
  };

  return {
    isPlaying,
    handleAudioToggle
  };
};
