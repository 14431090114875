
import React, { useState } from 'react';
import { useTranslations } from "@/hooks/useTranslations";
import { BottomNavigation } from "@/components/BottomNavigation";
import { SavedPhrasesList } from "@/components/phrases/SavedPhrasesList";
import { PhraseFlashcardDialog } from "@/components/phrases/PhraseFlashcardDialog";
import { PracticeModeDialog } from "@/components/phrases/PracticeModeDialog";
import { useSavedKeywords } from "@/hooks/useSavedKeywords";
import { useKeywordContext } from "@/hooks/useKeywordContext";

const Phrases = () => {
  const { t } = useTranslations();
  const [isPracticeMode, setIsPracticeMode] = useState(false);
  
  // Get the language settings to determine target and native languages
  const settings = localStorage.getItem('languageSettings');
  const { targetLanguageCode, nativeLanguageCode } = settings 
    ? JSON.parse(settings) 
    : { targetLanguageCode: 'en', nativeLanguageCode: 'en' };

  // Use our custom hooks to manage data and state
  const { savedKeywords, isLoading, handleMarkAsLearnt } = useSavedKeywords(
    targetLanguageCode, 
    nativeLanguageCode
  );
  
  const { 
    isFlashcardOpen,
    setIsFlashcardOpen,
    selectedKeyword,
    storyContext,
    handleFlashcardOpen
  } = useKeywordContext(targetLanguageCode);

  return (
    <>
      <div className="container mx-auto px-4 py-4 mb-20">
        
        <SavedPhrasesList 
          keywords={savedKeywords}
          isLoading={isLoading}
          targetLanguageCode={targetLanguageCode}
          nativeLanguageCode={nativeLanguageCode}
          onRemove={handleMarkAsLearnt}
          onSelect={handleFlashcardOpen}
        />
      </div>
      
      <PhraseFlashcardDialog 
        isOpen={isFlashcardOpen}
        onOpenChange={setIsFlashcardOpen}
        keyword={selectedKeyword}
        targetLanguageCode={targetLanguageCode}
        nativeLanguageCode={nativeLanguageCode}
        storyContext={storyContext}
      />
      
      <PracticeModeDialog
        isOpen={isPracticeMode}
        onOpenChange={setIsPracticeMode}
        keywords={savedKeywords}
        targetLanguageCode={targetLanguageCode}
        nativeLanguageCode={nativeLanguageCode}
      />
      
      <BottomNavigation />
    </>
  );
};

export default Phrases;
