
import { library } from '@fortawesome/fontawesome-svg-core';
import { 
  faPlay, 
  faPause, 
  faVolumeHigh, 
  faVolumeXmark, 
  faShareFromSquare, 
  faCircleQuestion,
  faBrain,
  faBook,
  faMessage,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faPlus,
  faCheck,
  faEllipsisVertical,
  faCircleArrowRight
} from '@fortawesome/free-solid-svg-icons';

import {
  faGlobe,
  faFlag,
  faLanguage,
  faEarthEurope,
  faEarthAmericas,
  faEarthAsia,
  faEarthAfrica
} from '@fortawesome/free-solid-svg-icons';

// Add icons to the library
library.add(
  faPlay,
  faPause,
  faVolumeHigh,
  faVolumeXmark,
  faShareFromSquare,
  faCircleQuestion,
  faBrain,
  faBook,
  faMessage,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faPlus,
  faCheck,
  faEllipsisVertical,
  faCircleArrowRight,
  faGlobe,
  faFlag,
  faLanguage,
  faEarthEurope,
  faEarthAmericas,
  faEarthAsia,
  faEarthAfrica
);

// Export icons for individual usage
export {
  faPlay,
  faPause,
  faVolumeHigh,
  faVolumeXmark,
  faShareFromSquare,
  faCircleQuestion,
  faBrain,
  faBook,
  faMessage,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faPlus,
  faCheck,
  faEllipsisVertical,
  faCircleArrowRight,
  faGlobe,
  faFlag,
  faLanguage,
  faEarthEurope,
  faEarthAmericas,
  faEarthAsia,
  faEarthAfrica
};
