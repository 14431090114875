
import { useEffect, useState } from 'react';
import enTranslations from '@/translations/en.json';
import deTranslations from '@/translations/de.json';
import ruTranslations from '@/translations/ru.json';
import esTranslations from '@/translations/es.json';
import itTranslations from '@/translations/it.json';
import ptTranslations from '@/translations/pt.json';
import frTranslations from '@/translations/fr.json';
import nlTranslations from '@/translations/nl.json';
import uaTranslations from '@/translations/ua.json';

export type LanguageCode = 'en' | 'de' | 'ru' | 'es' | 'it' | 'pt' | 'fr' | 'nl' | 'ua';

type LanguageName = 'English' | 'German' | 'Russian' | 'Spanish' | 'Italian' | 'Portuguese' | 'French' | 'Dutch' | 'Ukrainian';

type TranslationsType = Record<string, string | Record<string, any>>;

const translations: Record<LanguageCode, TranslationsType> = {
  en: enTranslations,
  de: deTranslations,
  ru: ruTranslations,
  es: esTranslations,
  it: itTranslations,
  pt: ptTranslations,
  fr: frTranslations,
  nl: nlTranslations,
  ua: uaTranslations,
};

const languageMap: Record<LanguageCode, LanguageName> = {
  en: 'English',
  de: 'German',
  ru: 'Russian',
  es: 'Spanish',
  it: 'Italian',
  pt: 'Portuguese',
  fr: 'French',
  nl: 'Dutch',
  ua: 'Ukrainian'
};

const languageCodeMap: Record<string, LanguageCode> = {
  english: 'en',
  german: 'de',
  russian: 'ru',
  spanish: 'es',
  italian: 'it',
  portuguese: 'pt',
  french: 'fr',
  dutch: 'nl',
  ukrainian: 'ua'
};

// Helper function to detect browser language
export const detectBrowserLanguage = (): { code: LanguageCode; name: LanguageName } => {
  try {
    const browserLang = (navigator.language || (navigator.languages && navigator.languages[0]) || 'en').split('-')[0].toLowerCase();
    const supportedCode = Object.keys(languageCodeMap).find(key => 
      key.toLowerCase() === browserLang || languageCodeMap[key] === browserLang
    );
    
    if (supportedCode) {
      const code = languageCodeMap[supportedCode] || 'en';
      return { code, name: languageMap[code] };
    }

    // Direct match with language code
    if (browserLang in translations) {
      return { 
        code: browserLang as LanguageCode, 
        name: languageMap[browserLang as LanguageCode] 
      };
    }
  } catch (e) {
    console.error('Error detecting browser language:', e);
  }
  
  // Default to English if detection fails
  return { code: 'en', name: 'English' };
};

// Helper function to convert language code to name
export const languageCodeToName = (code: LanguageCode): LanguageName => {
  return languageMap[code] || 'English';
};

// Helper function to convert language name to code
export const languageNameToCode = (name: string): LanguageCode => {
  const lowerName = name.toLowerCase();
  const code = languageCodeMap[lowerName];
  return code || 'en';
};

export const useTranslations = () => {
  const [language, setLanguage] = useState<LanguageCode>('en');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Get the user's preferred language from settings or browser
    const loadLanguageSettings = () => {
      setIsLoading(true);
      try {
        const settings = localStorage.getItem('languageSettings');
        if (settings) {
          const { nativeLanguageCode } = JSON.parse(settings);
          if (nativeLanguageCode && translations[nativeLanguageCode as LanguageCode]) {
            setLanguage(nativeLanguageCode as LanguageCode);
          } else {
            // If invalid language code in settings, use browser language
            const { code } = detectBrowserLanguage();
            setLanguage(code);
          }
        } else {
          // Use browser language as fallback
          const { code } = detectBrowserLanguage();
          setLanguage(code);
        }
      } catch (error) {
        console.error('Error loading language settings:', error);
        // If error, fallback to English
        setLanguage('en');
      } finally {
        setIsLoading(false);
      }
    };

    loadLanguageSettings();
  }, []);

  // Force refresh the translations with a new language
  const forceRefresh = (newLanguage: LanguageCode) => {
    if (translations[newLanguage]) {
      setLanguage(newLanguage);
    } else {
      console.error(`Language ${newLanguage} is not supported`);
    }
  };

  const t = (key: string, params?: Record<string, string>) => {
    try {
      const currentTranslations = translations[language] || translations.en;
      
      // Simple direct lookup first
      if (currentTranslations[key] && typeof currentTranslations[key] === 'string') {
        let translatedText = currentTranslations[key] as string;
        
        // Replace parameters if provided
        if (params) {
          Object.keys(params).forEach(paramKey => {
            translatedText = translatedText.replace(`{{${paramKey}}}`, params[paramKey]);
          });
        }
        
        return translatedText;
      }
      
      // Try dot notation for nested keys
      const parts = key.split('.');
      if (parts.length > 1) {
        let value: any = currentTranslations;
        
        for (const part of parts) {
          if (value && typeof value === 'object' && part in value) {
            value = value[part];
          } else {
            // Key not found in current language
            value = null;
            break;
          }
        }
        
        if (value && typeof value === 'string') {
          // Replace parameters if provided
          let translatedText = value as string;
          if (params) {
            Object.keys(params).forEach(paramKey => {
              translatedText = translatedText.replace(`{{${paramKey}}}`, params[paramKey]);
            });
          }
          return translatedText;
        }
      }
      
      // If not found in current language, try English as fallback
      if (language !== 'en') {
        const enTranslations = translations.en;
        
        // Try direct lookup in English
        if (enTranslations[key] && typeof enTranslations[key] === 'string') {
          let translatedText = enTranslations[key] as string;
          
          // Replace parameters if provided
          if (params) {
            Object.keys(params).forEach(paramKey => {
              translatedText = translatedText.replace(`{{${paramKey}}}`, params[paramKey]);
            });
          }
          
          return translatedText;
        }
        
        // Try nested lookup in English
        if (parts.length > 1) {
          let value: any = enTranslations;
          
          for (const part of parts) {
            if (value && typeof value === 'object' && part in value) {
              value = value[part];
            } else {
              // Key not found in English either
              value = null;
              break;
            }
          }
          
          if (value && typeof value === 'string') {
            // Replace parameters if provided
            let translatedText = value as string;
            if (params) {
              Object.keys(params).forEach(paramKey => {
                translatedText = translatedText.replace(`{{${paramKey}}}`, params[paramKey]);
              });
            }
            return translatedText;
          }
        }
      }
      
      // If all lookups fail, return the key itself
      return key;
    } catch (error) {
      console.error(`Translation error for key "${key}":`, error);
      return key;
    }
  };

  return {
    t,
    language,
    isLoading,
    currentLanguage: language, 
    forceRefresh,
  };
};
