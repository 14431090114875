
import React from 'react';
import { useTranslations } from '@/hooks/useTranslations';

interface CarouselItemProps {
  color: string;
  title: string;
  description: string;
  imageSrc: string;
  alt: string;
}

const CarouselItem = ({ color, title, description, imageSrc, alt }: CarouselItemProps) => {
  return (
    <div className={`landing-carousel-item ${color}`}>
      <h3 className="landing-carousel-title">{title}</h3>
      <p className="landing-carousel-description">{description}</p>
      <div className="landing-carousel-image-container">
        <img src={imageSrc} alt={alt} className="w-full h-auto" />
      </div>
    </div>
  );
};

interface CarouselSectionProps {
  refreshKey?: number;
}

const CarouselSection: React.FC<CarouselSectionProps> = ({ refreshKey }) => {
  const { t } = useTranslations();
  
  const carouselItems: CarouselItemProps[] = [
    {
      color: 'bg-[#FDE1F0]',
      title: t('landing.carousel.item1.title'),
      description: t('landing.carousel.item1.description'),
      imageSrc: '/lovable-uploads/carousel-1.png',
      alt: t('landing.carousel.item1.alt')
    },
    {
      color: 'bg-[#F2FCE2]',
      title: t('landing.carousel.item2.title'),
      description: t('landing.carousel.item2.description'),
      imageSrc: '/lovable-uploads/carousel-2.png',
      alt: t('landing.carousel.item2.alt')
    },
    {
      color: 'bg-[#D3E4FD]',
      title: t('landing.carousel.item3.title'),
      description: t('landing.carousel.item3.description'),
      imageSrc: '/lovable-uploads/carousel-3.png',
      alt: t('landing.carousel.item3.alt')
    },
    {
      color: 'bg-[#FDE1F0]',
      title: t('landing.carousel.item4.title'),
      description: t('landing.carousel.item4.description'),
      imageSrc: '/lovable-uploads/carousel-4.png',
      alt: t('landing.carousel.item4.alt')
    }
  ];

  return (
    <section className="landing-carousel">
      <div className="landing-carousel-container">
        <div className="landing-carousel-wrapper">
          {carouselItems.map((item, index) => (
            <CarouselItem 
              key={index}
              color={item.color}
              title={item.title}
              description={item.description}
              imageSrc={item.imageSrc}
              alt={item.alt}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default CarouselSection;
