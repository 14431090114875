
import React, { useState } from 'react';
import { useTranslations } from '@/hooks/useTranslations';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';
import { Input } from '@/components/ui/input';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';

interface FeedbackFormProps {
  source: string;
  className?: string;
}

const FeedbackForm: React.FC<FeedbackFormProps> = ({ source, className = '' }) => {
  const { t, currentLanguage } = useTranslations();
  const [rating, setRating] = useState<number | null>(null);
  const [feedback, setFeedback] = useState('');
  const [contactInfo, setContactInfo] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const emojis = ['😞', '🙁', '😐', '🙂', '😀'];

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (rating === null) {
      toast.error(t('feedback.error.selectRating'));
      return;
    }
    
    setIsSubmitting(true);
    
    try {
      const { error } = await supabase
        .from('contact')
        .insert([
          { 
            rating, 
            feedback, 
            contact_info: contactInfo,
            source,
            language: currentLanguage
          }
        ]);
        
      if (error) throw error;
      
      setIsSubmitted(true);
      toast.success(t('feedback.success'));
    } catch (error) {
      console.error('Error submitting feedback:', error);
      toast.error(t('feedback.error.submission'));
    } finally {
      setIsSubmitting(false);
    }
  };

  const resetForm = () => {
    setRating(null);
    setFeedback('');
    setContactInfo('');
    setIsSubmitted(false);
  };

  if (isSubmitted) {
    return (
      <div className={`feedback-submitted text-center p-6 ${className}`}>
        <h3 className="text-xl font-bold mb-4">{t('feedback.thankYou')}</h3>
        <p className="mb-4">{t('feedback.submitted')}</p>
        <Button onClick={resetForm} variant="default">{t('feedback.submitAnother')}</Button>
      </div>
    );
  }

  return (
    <div className={`feedback-form ${className}`}>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="rating-container">
          <h3 className="input-label">{t('feedback.rateExperience')}</h3>
          <div className="feedback-emojies">
            {emojis.map((emoji, index) => (
              <button
                key={index}
                type="button"
                onClick={() => setRating(index + 1)}
                className={`emoji-button ${
                  rating === index + 1 ? 'selected' : ''
                }`}
                aria-label={`Rating ${index + 1}`}
              >
                <span className="emoji">{emoji}</span>
              </button>
            ))}
          </div>
        </div>

        <div>
          <label htmlFor="feedback" className="input-label">
            {t('feedback.tellUs')}
          </label>
          <Textarea
            id="feedback"
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            placeholder={t('feedback.yourThoughts')}
            className="feedback-textarea comic-input"
          />
        </div>

        <div>
          <label htmlFor="contactInfo" className="input-label">
            {t('feedback.stayInLoop')} 
            <span className="text-gray-500 ml-2 font-normal">({t('common.optional')})</span>
          </label>
          <Input
            id="contactInfo"
            value={contactInfo}
            onChange={(e) => setContactInfo(e.target.value)}
            placeholder={t('feedback.emailOrSocial')}
            className="comic-input"
          />
        </div>

        <div>
          <Button 
            type="submit" 
            disabled={isSubmitting}
            className="w-full"
            variant="default"
          >
            {isSubmitting 
              ? t('feedback.submitting')
              : t('feedback.submitFeedback')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default FeedbackForm;
