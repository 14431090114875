
import { useState } from "react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Languages } from "lucide-react";
import { useTranslations } from "@/hooks/useTranslations";
import { StoryContentProps } from "@/types/storyComponents";

const splitIntoSentences = (text: string): string[] => {
  // If text is empty or undefined, return empty array
  if (!text) return [];
  
  // Split by line breaks and filter out empty lines
  const lines = text.split(/\n/).map(line => line.trim()).filter(line => line);
  
  return lines;
};

export const StoryContent = ({
  content,
  translation,
  isShortStory,
  showTranslation,
  highlightedText
}: StoryContentProps) => {
  const { t } = useTranslations();
  // Split content and translation into sentences
  const contentSentences = splitIntoSentences(content);
  const translationSentences = translation ? splitIntoSentences(translation) : [];
  
  // Split highlighted text into sentences to match the layout
  const highlightedSentences = highlightedText ? splitIntoSentences(highlightedText) : [];

  // Styles previously in App.css
  const shortStoryContentText = "text-2xl italic font-extrabold leading-[32px]";
  const longStoryContentText = "relative cursor-pointer text-lg";
  const chipTranslate = "inline-flex items-center cursor-pointer rounded-full px-2 py-1 text-xs bg-[rgba(26,27,48,0.09)] text-[rgba(27,28,50,0.65)] text-[0.7rem] font-medium py-[0.15rem] px-[0.4rem] gap-[0.1rem] font-normal ml-[0.2rem]";
  const chipTranslateIcon = "text-[rgba(27,28,50,0.65)]";
  const highlightedClass = "bg-[rgba(26,27,48,0.09)]";

  return (
    <div className="relative mb-4 mt-4">
      {contentSentences.map((sentence, index) => (
        <div key={index} className="mb-2 group relative">
          <div className="flex items-start gap-2">
            <div className="relative flex-1">
              
              
              {/* Separate translation chip with its own popover */}
              {translationSentences[index] && (
                <Popover>
                  <PopoverTrigger asChild>
                    <p
                      className={`${isShortStory
                          ? shortStoryContentText
                          : longStoryContentText
                        } flex-grow`}
                    >
                      {sentence}
                      <span
                        className={chipTranslate}
                      >
                        <Languages size={11} className={chipTranslateIcon} />
                        {index === 0 && t("story.tab.translate")}
                      </span>
                    </p>
                  </PopoverTrigger>
                  <PopoverContent 
                    className="bg-[#1A1B30] text-white border-none p-3 max-w-sm"
                    side="top"
                    align="start"
                  >
                    {translationSentences[index]}
                  </PopoverContent>
                </Popover>
              )}
              
              {highlightedSentences[index] && (
                <p 
                  className={`absolute top-0 left-0 right-0 pointer-events-none select-none z-10 m-0 ${
                    isShortStory 
                      ? "text-2xl italic font-extrabold leading-[32px]"
                      : "text-lg"
                  }`} 
                  style={{
                    WebkitUserSelect: 'none',
                    MozUserSelect: 'none',
                    msUserSelect: 'none',
                    userSelect: 'none'
                  }}
                >
                 <span className={highlightedClass}>
                  {highlightedSentences[index]}
                </span>
                </p>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
