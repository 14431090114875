
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TargetLanguage } from '@/types/language';

interface LanguageBadgeProps {
  language: TargetLanguage;
  displayText?: string; // Add this prop to display localized text
  iconComponent?: React.ReactNode;
  iconFa?: any;
  isActive: boolean;
  onClick: () => void;
}

export const LanguageBadge = ({ 
  language, 
  displayText,
  iconComponent, 
  iconFa, 
  isActive, 
  onClick 
}: LanguageBadgeProps) => {
  return (
    <div 
      className={`language-badge ${isActive ? 'active' : ''}`}
      onClick={onClick}
    >
      {iconComponent ? (
        <span className="icon-wrapper">{iconComponent}</span>
      ) : (
        iconFa && <FontAwesomeIcon icon={iconFa} className="mr-1" />
      )}
      <span>{displayText || language}</span>
    </div>
  );
};
