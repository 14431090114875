
import { useState } from 'react';
import { supabase } from "@/integrations/supabase/client";
import { StoryKeyword } from "@/types/story";
import { toast } from "sonner";

export const useKeywordContext = (targetLanguageCode: string) => {
  const [isFlashcardOpen, setIsFlashcardOpen] = useState(false);
  const [selectedKeyword, setSelectedKeyword] = useState<StoryKeyword | null>(null);
  const [storyContext, setStoryContext] = useState<{
    content: string;
    imageUrl?: string;
    type: 'long' | 'short';
    gradient?: string;
  } | undefined>(undefined);

  const handleFlashcardOpen = async (keyword: StoryKeyword) => {
    try {
      setSelectedKeyword(keyword);
      
      // If the keyword has associated stories, fetch context for the first one
      if (keyword.associated_stories && keyword.associated_stories.length > 0) {
        const storyId = keyword.associated_stories[0];
        
        // Fetch the story from stories
        const { data: storyData, error: storyError } = await supabase
          .from('stories')
          .select('id, type, image_url, content_json, gradient')
          .eq('id', storyId)
          .maybeSingle();  // Using maybeSingle instead of single to avoid error
          
        if (storyError) {
          console.error(`Error fetching story for keyword ${keyword.id}:`, storyError);
          setIsFlashcardOpen(true);
          return;
        }
        
        if (!storyData) {
          console.error(`No story data found for ID ${storyId}`);
          setIsFlashcardOpen(true);
          return;
        }
        
        // Extract content for the target language
        const contentObj = storyData.content_json?.[targetLanguageCode];
        const content = typeof contentObj === 'object' ? contentObj.text : contentObj || '';
        
        // For long stories, extract just a sentence containing the keyword
        let contextContent = content;
        if (storyData.type === 'long' && content) {
          const word = keyword.word;
          const sentences = content.split(/(?<=[.!?])\s+/);
          const sentenceWithKeyword = sentences.find(s => 
            s.toLowerCase().includes(word.toLowerCase())
          ) || sentences[0] || '';
          
          contextContent = sentenceWithKeyword;
        }
        
        const gradients = [
          "linear-gradient(98deg, #8FEFFC 0%, #DEF6A8 100%)",
          "linear-gradient(98deg, #DEF6A8 0%, #FFC7EE 100%)",
          "linear-gradient(98deg, #FFC7EE 0%, #68D4F9 100%)"
        ];
        
        const getRandomGradient = (id: string) => {
          if (!id) return gradients[0];
          const index = Math.abs(id.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0)) % gradients.length;
          return gradients[index];
        };
        
        setStoryContext({
          content: contextContent,
          imageUrl: storyData.image_url,
          type: storyData.type,
          gradient: storyData.gradient || getRandomGradient(storyData.id)
        });
      } else {
        setStoryContext(undefined);
      }
      
      setIsFlashcardOpen(true);
    } catch (err) {
      console.error('Error opening flashcard:', err);
      toast.error('Failed to open flashcard');
    }
  };

  return {
    isFlashcardOpen,
    setIsFlashcardOpen,
    selectedKeyword,
    storyContext,
    handleFlashcardOpen
  };
};
