
import { NativeLanguage } from "@/types/language";
import { useTranslations } from "@/hooks/useTranslations";
import { getLanguageCode } from "@/utils/languageUtils";
import { 
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue, 
} from "@/components/ui/select";
import * as CustomIcons from '@/lib/customIcons';

interface NativeLanguageSelectorProps {
  value: NativeLanguage;
  onChange: (value: NativeLanguage) => void;
  label: string;
  customClass?: string;
}

const nativeLanguageNames = {
  English: "I speak English",
  German: "Ich spreche Deutsch",
  Spanish: "Hablo español",
  French: "Je parle français",
  Italian: "Parlo italiano",
  Portuguese: "Eu falo português",
  Russian: "Я говорю по-русски",
  Ukrainian: "Я розмовляю українською",
  Dutch: "Ik spreek Nederlands"
};

export const NativeLanguageSelector = ({ 
  value, 
  onChange, 
  label,
  customClass 
}: NativeLanguageSelectorProps) => {
  const { t } = useTranslations();
  
  const nativeLanguages: { id: NativeLanguage; code: string }[] = [
    { id: 'English', code: 'en' },
    { id: 'German', code: 'de' },
    { id: 'Spanish', code: 'es' },
    { id: 'French', code: 'fr' },
    { id: 'Italian', code: 'it' },
    { id: 'Portuguese', code: 'pt' },
    { id: 'Russian', code: 'ru' },
    { id: 'Ukrainian', code: 'ua' },
    { id: 'Dutch', code: 'nl' }
  ];

  return (
    <div className="h-full">
      <Select value={value} onValueChange={(value) => onChange(value as NativeLanguage)}>
        <SelectTrigger className={`w-full h-full px-4 ${customClass || "comic-select"} language-selector-custom`}>
          <div className="flex flex-col items-start text-left">
            <SelectValue placeholder={t('language.native_language')} />
          </div>
        </SelectTrigger>
        <SelectContent className="comic-select-content">
          {nativeLanguages
            .sort((a, b) => a.id.localeCompare(b.id))
            .map((language) => {
              const IconComponent = CustomIcons[language.code as keyof typeof CustomIcons];
              
              return (
                <SelectItem key={language.id} value={language.id}>
                  <div className="flex items-center gap-2">
                    {IconComponent && <IconComponent size="1em" />}
                    <span>{nativeLanguageNames[language.id as keyof typeof nativeLanguageNames]}</span>
                  </div>
                </SelectItem>
              );
            })}
        </SelectContent>
      </Select>
    </div>
  );
};
