
import { useState, useRef } from "react";
import { supabase } from "@/integrations/supabase/client";
import { LanguageCode } from "@/types/story";

interface AIAudioOptions {
  onHighlightUpdate?: (highlightedText: string) => void;
}

export const useAIAudioGeneration = (text: string, options?: AIAudioOptions) => {
  const [isGeneratingElevenLabsAudio, setIsGeneratingElevenLabsAudio] = useState(false);
  const [isElevenLabsPlaying, setIsElevenLabsPlaying] = useState(false);
  const elevenLabsAudioRef = useRef<HTMLAudioElement | null>(null);

  const handleElevenLabsToggle = async (language: LanguageCode = 'en') => {
    if (isElevenLabsPlaying && elevenLabsAudioRef.current) {
      elevenLabsAudioRef.current.pause();
      setIsElevenLabsPlaying(false);
      return;
    }

    try {
      if (!elevenLabsAudioRef.current) {
        setIsGeneratingElevenLabsAudio(true);
        
        const { data, error } = await supabase.functions.invoke('generate-elevenlabs-audio', {
          body: {
            text: text,
            language: language
          }
        });

        if (error) throw error;

        const audioData = data.audioContent;
        const audioBlob = new Blob(
          [Uint8Array.from(atob(audioData), c => c.charCodeAt(0))],
          { type: 'audio/mp3' }
        );
        const audioUrl = URL.createObjectURL(audioBlob);

        const setupAudioElement = (audio: HTMLAudioElement) => {
          audio.addEventListener('timeupdate', () => {
            const currentTime = audio.currentTime;
            const duration = audio.duration;
            const words = text.split(' ');
            const wordIndex = Math.floor((currentTime / duration) * words.length);
            options?.onHighlightUpdate?.(words.slice(0, wordIndex + 1).join(' '));
          });
          audio.addEventListener('ended', () => {
            setIsElevenLabsPlaying(false);
            options?.onHighlightUpdate?.("");
          });
          audio.addEventListener('pause', () => {
            setIsElevenLabsPlaying(false);
          });
          audio.addEventListener('play', () => {
            setIsElevenLabsPlaying(true);
          });
        };

        elevenLabsAudioRef.current = new Audio(audioUrl);
        setupAudioElement(elevenLabsAudioRef.current);
      }

      await elevenLabsAudioRef.current.play();
      setIsElevenLabsPlaying(true);
    } catch (error) {
      console.error('Error with ElevenLabs audio:', error);
      setIsElevenLabsPlaying(false);
    } finally {
      setIsGeneratingElevenLabsAudio(false);
    }
  };

  return {
    isGeneratingElevenLabsAudio,
    isElevenLabsPlaying,
    handleElevenLabsToggle
  };
};
