
import React from 'react';
import { Button } from "@/components/ui/button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';

const Offline = () => {
  const attemptReconnect = () => {
    window.location.reload();
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-6 text-center">
      <h1 className="text-3xl font-bold mb-4">You're Offline</h1>
      <p className="mb-8 text-gray-600">
        It looks like you've lost your internet connection. 
        Some features may not be available until you reconnect.
      </p>
      <Button onClick={attemptReconnect}>
        <FontAwesomeIcon icon={faCircleArrowRight} className="mr-2" /> Try Again
      </Button>
    </div>
  );
};

export default Offline;
