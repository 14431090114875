
import { TargetLanguageOption } from "@/types/language";

export const targetLanguages: TargetLanguageOption[] = [
  { id: 'German', code: 'de', available: true },
  { id: 'English', code: 'en', available: true },
  { id: 'Russian', code: 'ru', available: true },
  { id: 'Spanish', code: 'es', available: true },
  { id: 'Italian', code: 'it', available: true },
  { id: 'Portuguese', code: 'pt', available: true },
  { id: 'French', code: 'fr', available: true },
  { id: 'Dutch', code: 'nl', available: true },
  { id: 'Ukrainian', code: 'ua', available: true }
];

export const getLanguageCode = (language: string): string => {
  switch (language) {
    case 'German': return 'de';
    case 'English': return 'en';
    case 'Russian': return 'ru';
    case 'Spanish': return 'es';
    case 'Italian': return 'it';
    case 'Portuguese': return 'pt';
    case 'French': return 'fr';
    case 'Dutch': return 'nl';
    case 'Ukrainian': return 'ua';
    default: return 'en';
  }
};
