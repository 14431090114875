
import { StoryKeyword } from "@/types/story";
import { Button } from "@/components/ui/button";
import { toast } from "sonner";
import { useAudioPlayback } from "@/hooks/useAudioPlayback";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeHigh, faEllipsisVertical, faPlus, faCheck, faCirclePause} from '@fortawesome/free-solid-svg-icons';

import { faCirclePlay } from '@fortawesome/free-regular-svg-icons';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

interface KeywordItemProps {
  keyword: StoryKeyword;
  targetLanguageCode: string;
  nativeLanguageCode: string;
  onRemove?: (keywordId: string) => void;
  onSaveToggle?: (keyword: StoryKeyword) => void;
  isSaved?: boolean;
  isPlaying?: boolean;
  onClick?: () => void;
}

export const KeywordItem = ({ 
  keyword, 
  targetLanguageCode, 
  nativeLanguageCode,
  onRemove,
  onSaveToggle,
  isSaved,
  isPlaying = false,
  onClick
}: KeywordItemProps) => {
  const translations = keyword.translations || [];
  const targetWord = translations.find(t => t.language === targetLanguageCode)?.translation;
  const nativeWord = translations.find(t => t.language === nativeLanguageCode)?.translation;
  
  // Handle both formats of audio URL storage
  const audioFieldName = `keyword_audio_${targetLanguageCode}`;
  const targetAudioUrl = keyword[audioFieldName] || keyword.audioUrl;
  
  // Create a truly unique key for each keyword to prevent audio conflicts
  const uniqueAudioKey = `keyword-${keyword.id}-${targetLanguageCode}`;
  const { isPlaying: audioIsPlaying, handleAudioToggle } = useAudioPlayback(targetWord || keyword.word, uniqueAudioKey);

  const handleAudioClick = async (e: React.MouseEvent) => {
    // Stop the event from propagating to the parent element
    e.stopPropagation();
    
    if (!targetAudioUrl) {
      toast.error('No audio available for this word yet');
      return;
    }

    await handleAudioToggle(targetAudioUrl);
  };

  const handleMarkAsLearnt = (e: React.MouseEvent) => {
    // Stop the event from propagating to the parent element
    e.stopPropagation();
    
    if (onRemove) {
      onRemove(keyword.id);
      toast.success('Word marked as learnt');
    }
  };

  const handleItemClick = () => {
    if (onClick) {
      onClick();
    }
  };

  // Check if audio is available
  const hasAudio = Boolean(targetAudioUrl);

  // Styles previously in App.css
  const iconPrimary = "icon-primary";
  const iconSecondary = "icon-secondary";

  return (
    <div 
      className="flex items-center justify-between py-3 keyword-item last:border-b-0 gap-1"
      onClick={handleItemClick}
    >
        <div className="flex flex-col flex-1">
          <p className="font-medium">{targetWord || keyword.word}</p>
          <p className="text-sm text-gray-500">{nativeWord || keyword.word}</p>
        </div>
        {hasAudio && (
          <Button 
            variant="ghost" 
            size="icon"
            className="h-10 w-10 rounded-lg hover:btn-default-hover"
            onClick={handleAudioClick}
            title="Play audio"
          >
            {audioIsPlaying ? (
              <FontAwesomeIcon icon={faCirclePause} className="icon-primary keyword-action-icon" />
            ) : (
              <FontAwesomeIcon icon={faCirclePlay} className="icon-secondary keyword-action-icon" />
            )}
          </Button>
        )}
      
      {onSaveToggle && (
        <Button
          variant="ghost"
          size="icon"
          className={`h-10 w-10 ${isSaved ? "" : ""}`}
          onClick={(e) => {
            e.stopPropagation();
            onSaveToggle(keyword);
          }}
        >
          {isSaved ? (
            <FontAwesomeIcon icon={faCheck} className="icon-primary keyword-action-icon" />
          ) : (
            <FontAwesomeIcon icon={faPlus} className="icon-secondary keyword-action-icon" />
          )}
        </Button>
      )}
      
      {onRemove && (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button 
              variant="ghost" 
              size="icon" 
              className="h-8 w-8 hover:btn-default-hover"
              onClick={(e) => e.stopPropagation()}
            >
              <FontAwesomeIcon icon={faEllipsisVertical} className="icon-secondary keyword-action-icon" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end" onClick={(e) => e.stopPropagation()}>
            <DropdownMenuItem onClick={handleMarkAsLearnt}>
              Mark as learnt
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </div>
  );
};
