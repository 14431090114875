
import { StoryKeyword } from "@/types/story";
import { KeywordItem } from "@/components/keyword/KeywordItem";
import { LanguageCode } from "@/types/story";
import { useEffect, useState } from "react";
import { StoryKeywordsProps } from "@/types/storyComponents";
import { useTranslations } from "@/hooks/useTranslations";

export const StoryKeywords = ({
  keywords,
  targetLanguageCode,
  nativeLanguageCode
}: StoryKeywordsProps) => {
  const [savedKeywords, setSavedKeywords] = useState<string[]>([]);
  const { t } = useTranslations();
  
  // Load saved keywords on mount
  useEffect(() => {
    const saved = localStorage.getItem('savedKeywords');
    if (saved) {
      const parsedKeywords = JSON.parse(saved);
      setSavedKeywords(parsedKeywords.map((k: any) => k.id));
    }
  }, []);

  const handleSaveKeyword = (keyword: any) => {
    const isKeywordSaved = savedKeywords.includes(keyword.id);
    let updatedKeywords = [];
    let savedKeywordsArray = [];
    
    // Get the current saved keywords
    const saved = localStorage.getItem('savedKeywords');
    if (saved) {
      savedKeywordsArray = JSON.parse(saved);
    }
    
    if (isKeywordSaved) {
      // Remove keyword if already saved
      updatedKeywords = savedKeywords.filter(id => id !== keyword.id);
      const updatedSavedKeywords = savedKeywordsArray.filter((k: any) => k.id !== keyword.id);
      localStorage.setItem('savedKeywords', JSON.stringify(updatedSavedKeywords));
    } else {
      // Add keyword if not saved
      updatedKeywords = [...savedKeywords, keyword.id];
      const updatedSavedKeywords = [...savedKeywordsArray, keyword];
      localStorage.setItem('savedKeywords', JSON.stringify(updatedSavedKeywords));
    }
    
    setSavedKeywords(updatedKeywords);
  };

  if (!keywords || keywords.length === 0) {
    return null;
  }

  return (
    <div className="mt-4 pt-4 pb-2">
      {keywords.map((keyword) => (
        <KeywordItem
          key={`story-keyword-${keyword.id}`}
          keyword={keyword}
          targetLanguageCode={targetLanguageCode}
          nativeLanguageCode={nativeLanguageCode}
          onSaveToggle={handleSaveKeyword}
          isSaved={savedKeywords.includes(keyword.id)}
        />
      ))}
    </div>
  );
};
