import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslations, detectBrowserLanguage } from '@/hooks/useTranslations';
import { NativeLanguage, TargetLanguage } from '@/types/language';
import { getLanguageCode } from '@/utils/languageUtils';
import { HeroSection } from './HeroSection';
import CarouselSection from './CarouselSection';
import * as CustomIcons from '@/lib/customIcons';
import FeedbackSection from '@/components/feedback/FeedbackSection';
import { NativeLanguageSelector } from '@/components/language/NativeLanguageSelector';
import { Button } from '@/components/ui/button';

const LandingPage: React.FC = () => {
  const navigate = useNavigate();
  const { t, forceRefresh } = useTranslations();
  const [nativeLanguage, setNativeLanguage] = useState<NativeLanguage>("English");
  const [targetLanguage, setTargetLanguage] = useState<TargetLanguage>("Spanish");
  const [refreshKey, setRefreshKey] = useState<number>(0);

  useEffect(() => {
    const settings = localStorage.getItem('languageSettings');
    if (settings) {
      const parsedSettings = JSON.parse(settings);
      if (parsedSettings.nativeLanguage) setNativeLanguage(parsedSettings.nativeLanguage);
      if (parsedSettings.targetLanguage) setTargetLanguage(parsedSettings.targetLanguage);
    } else {
      const detectedLanguage = detectBrowserLanguage();
      setNativeLanguage(detectedLanguage.name as NativeLanguage);
      
      if (detectedLanguage.name === "Spanish") {
        setTargetLanguage("German");
      }
    }
  }, []);

  const handleNativeLanguageChange = (newLanguage: NativeLanguage) => {
    setNativeLanguage(newLanguage);
    const languageCode = getLanguageCode(newLanguage).toLowerCase();
    
    const initialSettings = {
      nativeLanguage: newLanguage,
      nativeLanguageCode: languageCode,
      targetLanguage: targetLanguage,
      targetLanguageCode: getLanguageCode(targetLanguage),
    };
    localStorage.setItem('languageSettings', JSON.stringify(initialSettings));
    forceRefresh(languageCode as any);
    
    setRefreshKey(prevKey => prevKey + 1);
  };

  const handleStartLearning = () => {
    const settings = {
      targetLanguage: targetLanguage,
      targetLanguageCode: getLanguageCode(targetLanguage),
      nativeLanguage,
      nativeLanguageCode: getLanguageCode(nativeLanguage),
    };
    localStorage.setItem('languageSettings', JSON.stringify(settings));
    window.location.href = 'https://istorio.netlify.app/';
  };

  const handleVisitWebsite = () => {
    window.open('https://istorio.netlify.app/', '_blank');
  };

  const handleLanguageBadgeClick = (language: TargetLanguage) => {
    if (language !== nativeLanguage) {
      setTargetLanguage(language);
    }
  };

  const getLanguageIcon = (language: TargetLanguage) => {
    const code = getLanguageCode(language).toLowerCase();
    const IconComponent = CustomIcons[code as keyof typeof CustomIcons];
    return { iconComponent: IconComponent ? <IconComponent size="1em" /> : null };
  };

  return (
    <div className="min-h-screen flex flex-col px-2 md:px-4">
      <div className="container mx-auto flex justify-start p-0">
        <div className="language-selector-gradient">
          <div className="md:pl-8">
          <NativeLanguageSelector
            value={nativeLanguage}
            onChange={handleNativeLanguageChange}
            label={t('landing.i_speak')}
            customClass="landing-selector-select"
          />
        </div>
        </div>
      </div>

      <div className="container">
        <h2 className="landing-logo">
          {t('landing.footer.title')}<br /> 
          <span className="landing-logo-subtext">
            {t('landing.hero.title')}
          </span>
        </h2>
      </div>
      
      <HeroSection 
        key={`hero-${refreshKey}`}
        t={t}
        nativeLanguage={nativeLanguage}
        targetLanguage={targetLanguage}
        onNativeLanguageChange={handleNativeLanguageChange}
        onTargetLanguageChange={setTargetLanguage}
        onStartLearning={handleStartLearning}
        getLanguageIcon={getLanguageIcon}
        handleLanguageBadgeClick={handleLanguageBadgeClick}
      />

      <CarouselSection key={`carousel-${refreshKey}`} refreshKey={refreshKey} />

      <section className="landing-how-it-works container">
        <div className="landing-how-it-works-content">
            <h2 className="section_title">
              {t('landing.how_it_works.title')}
            </h2>
            <p className="landing-how-it-works-p">
              {t('landing.how_it_works.text')}           
            </p>
        </div>
        <div className="landing-feature-content mt-8">
          <div className="landing-feature-text">

            <div>
              <h3 className="landing-feature-title">
                {t('landing.feature1.title')}
              </h3>
              <p className="landing-feature-description">
                {t('landing.feature1.desc')}
              </p>
            </div>

            <div>
              <h3 className="landing-feature-title">
                {t('landing.feature2.title')}
              </h3>
              <p className="landing-feature-description">
                {t('landing.feature2.desc')}
              </p>
            </div>

            <div >
              <h3 className="landing-feature-title">
                {t('landing.how_it_works.step5.title')}
              </h3>
              <p className="landing-feature-description">
                {t('landing.how_it_works.step5.desc')}
              </p>
            </div>
            <div className="flex gap-4 mt-8">
              <Button 
                onClick={handleVisitWebsite}
                className="start-learning-button"
                variant="default"
              >
                {t('onboarding.start_learning')}
              </Button>
            </div>

          </div>
        </div>
      </section>
      
      <FeedbackSection source="landing_page" />
      
      <footer className="landing-footer">
        <div className="max-w-7xl mx-auto">
          <p className="landing-footer-copyright">
            {t('landing.footer.copyright')}
          </p>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
