import { useState, useEffect } from "react";
import { LanguageSelector } from "./LanguageSelector";
import { useTranslations } from "@/hooks/useTranslations";
import { useLocation, Link } from "react-router-dom";
import { LanguageLevel } from "@/types/language";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { 
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { useSavedKeywords } from "@/hooks/useSavedKeywords";
import { PracticeModeDialog } from "@/components/phrases/PracticeModeDialog";

const getLevelColor = (level: string): string => {
  const colors = {
    'A1': '#F2FCE2', // Soft Green
    'A2': '#FEF7CD', // Soft Yellow
    'B1': '#D3E4FD', // Soft Blue
    'B2': '#FDE1D3', // Soft Peach
    'C1': '#E5DEFF', // Soft Purple
    'C2': '#FFDEE2', // Soft Pink
  };
  return colors[level as keyof typeof colors] || '#F2FCE2';
};

export const Navigation = () => {
  const [showLanguageSelector, setShowLanguageSelector] = useState(false);
  const [targetLanguage, setTargetLanguage] = useState<string>('DE');
  const [selectedLevel, setSelectedLevel] = useState<LanguageLevel>('A1');
  const [isPracticeMode, setIsPracticeMode] = useState(false);
  const { t } = useTranslations();
  const location = useLocation();

  const settings = localStorage.getItem('languageSettings');
  const { targetLanguageCode, nativeLanguageCode } = settings 
    ? JSON.parse(settings) 
    : { targetLanguageCode: 'en', nativeLanguageCode: 'en' };

  const { savedKeywords } = useSavedKeywords(
    targetLanguageCode, 
    nativeLanguageCode

  );

  useEffect(() => {
    const getLanguageSettings = () => {
      const settings = localStorage.getItem('languageSettings');
      if (settings) {
        try {
          const { targetLanguageCode, levels } = JSON.parse(settings);
          setTargetLanguage(targetLanguageCode.toUpperCase());
          const highestLevel = levels.reduce((highest: LanguageLevel, current: LanguageLevel) => {
            const levelOrder = ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'];
            return levelOrder.indexOf(current) > levelOrder.indexOf(highest) ? current : highest;
          }, levels[0] as LanguageLevel);
          setSelectedLevel(highestLevel || 'A1');
        } catch (error) {
          console.error("Error parsing language settings", error);
        }
      }
    };

    getLanguageSettings();
  }, []);

  const getTitle = () => {
    switch (location.pathname) {
      case '/':
      case '/app':
        return t("nav.stories");
      case '/phrases':
        return t("nav.phrases");
      case '/control':
        return t("nav.control");
      default:
        return t("nav.stories");
    }
  };

  return (
    <>
      <header className="top-nav">
        <div className="flex items-center gap-2">
          <button onClick={() => setShowLanguageSelector(true)} className="navigation-indicators">
            <span className="navigation-indicator-language">{targetLanguage}</span>
            <span 
              className="navigation-indicator-level"
              style={{ backgroundColor: getLevelColor(selectedLevel) }}
            >
              {selectedLevel}
            </span>
          </button>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <button className="font-poppins text-[26px] font-bold text-[#2C2C2C] leading-[40px] tracking-[0.25px] flex items-center">
                {getTitle()}
                <FontAwesomeIcon icon={faChevronDown} className="h-4 w-4 ml-2 pt-1" />
              </button>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="bg-white">
              <DropdownMenuItem asChild>
                <Link to="/app" className="cursor-pointer">
                  {t("nav.stories")}
                </Link>
              </DropdownMenuItem>
              <DropdownMenuItem asChild>
                <Link to="/phrases" className="cursor-pointer">
                  {t("nav.phrases")}
                </Link>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>

        {savedKeywords.length > 0 && (
          <Button 
            onClick={() => setIsPracticeMode(true)}
            className="comic-button"
          >
            {t('practice')}
          </Button>
        )}
      </header>

      <LanguageSelector 
        open={showLanguageSelector} 
        onClose={() => {
          setShowLanguageSelector(false);
          const settings = localStorage.getItem('languageSettings');
          if (settings) {
            try {
              const { targetLanguageCode, levels } = JSON.parse(settings);
              setTargetLanguage(targetLanguageCode.toUpperCase());
              const highestLevel = levels.reduce((highest: LanguageLevel, current: LanguageLevel) => {
                const levelOrder = ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'];
                return levelOrder.indexOf(current) > levelOrder.indexOf(highest) ? current : highest;
              }, levels[0] as LanguageLevel);
              setSelectedLevel(highestLevel || 'A1');
            } catch (error) {
              console.error("Error parsing language settings", error);
            }
          }
        }} 
      />

      {isPracticeMode && (
        <PracticeModeDialog
          isOpen={isPracticeMode}
          onOpenChange={setIsPracticeMode}
          keywords={savedKeywords}
          targetLanguageCode={targetLanguageCode}
          nativeLanguageCode={nativeLanguageCode}
        />
      )}
    </>
  );
};
