import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { X } from "lucide-react";
import { StoryKeyword } from "@/types/story";
import { PhraseFlashcardDialog } from "@/components/phrases/PhraseFlashcardDialog";
import { Progress } from "@/components/ui/progress";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeUp, faVolumeXmark } from '@fortawesome/free-solid-svg-icons';
import { useTranslations } from "@/hooks/useTranslations";
import { Switch } from "@/components/ui/switch";

interface PracticeModeDialogProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  keywords: StoryKeyword[];
  targetLanguageCode: string;
  nativeLanguageCode: string;
}

export const PracticeModeDialog: React.FC<PracticeModeDialogProps> = ({
  isOpen,
  onOpenChange,
  keywords,
  targetLanguageCode,
  nativeLanguageCode
}) => {
  const { t } = useTranslations();
  const [currentStep, setCurrentStep] = useState<'settings' | 'practice'>('settings');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [audioEnabled, setAudioEnabled] = useState(true);
  const [showFlashcard, setShowFlashcard] = useState(false);
  
  useEffect(() => {
    if (isOpen) {
      setCurrentStep('settings');
      setCurrentIndex(0);
      setAudioEnabled(true);
    } else {
      setShowFlashcard(false);
    }
  }, [isOpen]);

  const goNext = () => {
    if (currentIndex < keywords.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setShowFlashcard(true);
    } else {
      onOpenChange(false);
    }
  };

  const goPrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      setShowFlashcard(true);
    }
  };

  const startPractice = () => {
    setCurrentStep('practice');
    if (keywords.length > 0) {
      setShowFlashcard(true);
    }
  };

  const progress = keywords.length > 0 
    ? Math.round(((currentIndex) / keywords.length) * 100) 
    : 0;

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-md p-0 gap-0 overflow-hidden bg-white">
        {currentStep === 'settings' ? (
          <div className="p-6 relative">
            <div className="flex items-center justify-between mb-6">
              <div className="w-8"></div>
              <div className="text-center">
                <Progress value={0} max={100} className="mb-1 bg-gray-200 h-2" />
                <span className="text-xs text-gray-500">0/{keywords.length}</span>
              </div>
              <div className="w-8"></div>
            </div>

            <div className="flex flex-col items-center justify-center py-10">
              <h2 className="text-xl font-semibold mb-4">
                {t('sound_settings')}
              </h2>
              <p className="text-center text-gray-600 mb-8">
                {t('want_audio')}
              </p>

              <div className="flex items-center justify-center space-x-2 bg-gray-100 rounded-full p-1 w-full max-w-xs">
                <button 
                  className={`flex items-center justify-center space-x-2 py-2 px-4 rounded-full ${audioEnabled ? 'bg-white shadow-sm' : ''}`}
                  onClick={() => setAudioEnabled(true)}
                >
                  <FontAwesomeIcon icon={faVolumeUp} />
                  <span>{t('sound_on')}</span>
                </button>
                <button 
                  className={`flex items-center justify-center space-x-2 py-2 px-4 rounded-full ${!audioEnabled ? 'bg-white shadow-sm' : ''}`}
                  onClick={() => setAudioEnabled(false)}
                >
                  <FontAwesomeIcon icon={faVolumeXmark} />
                  <span>{t('sound_off')}</span>
                </button>
              </div>

              <Button
                onClick={startPractice}
                className="bg-[#D9EBA9] text-[#1A1B30] hover:bg-[#C9E987] font-medium py-2 px-6 rounded-md mt-10 w-full max-w-xs"
              >
                {t('start')}
              </Button>
            </div>
          </div>
        ) : (
          <div className="relative">
            <Button
              variant="ghost" 
              size="icon"
              className="absolute right-2 top-2 h-8 w-8 z-10"
              onClick={() => onOpenChange(false)}
            >
              <X className="h-5 w-5" />
            </Button>
            
            <div className="flex items-center justify-between p-4">
              <Button
                variant="ghost"
                size="icon"
                onClick={() => setAudioEnabled(!audioEnabled)}
                className="h-10 w-10"
              >
                <FontAwesomeIcon 
                  icon={audioEnabled ? faVolumeUp : faVolumeXmark} 
                  className="text-gray-700" 
                />
              </Button>
              
              <div className="text-center flex-1">
                <Progress value={progress} max={100} className="mb-1 bg-gray-200 h-2" />
                <span className="text-xs text-gray-500">{currentIndex}/{keywords.length}</span>
              </div>
              
              <Button
                variant="ghost" 
                size="icon"
                className="h-10 w-10"
                onClick={() => onOpenChange(false)}
              >
                <X className="h-5 w-5" />
              </Button>
            </div>
            
            <div className="flex justify-between items-center absolute top-1/2 left-0 right-0 z-20 px-2">
              <Button
                variant="ghost"
                size="icon"
                onClick={goPrevious}
                disabled={currentIndex === 0}
                className="h-10 w-10 bg-white rounded-full shadow-md"
              >
                <span className="text-xl font-bold">&lt;</span>
              </Button>
              
              <Button
                variant="ghost"
                size="icon"
                onClick={goNext}
                className="h-10 w-10 bg-white rounded-full shadow-md"
              >
                <span className="text-xl font-bold">&gt;</span>
              </Button>
            </div>
            
            {keywords.length > 0 && showFlashcard && (
              <PhraseFlashcardDialog
                isOpen={showFlashcard}
                onOpenChange={(open) => {
                  setShowFlashcard(open);
                  if (!open) {
                    goNext();
                  }
                }}
                keyword={keywords[currentIndex]}
                targetLanguageCode={targetLanguageCode}
                nativeLanguageCode={nativeLanguageCode}
                autoplayAudio={audioEnabled}
                inPracticeMode={true}
              />
            )}
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};
