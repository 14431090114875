
import { Toaster as Sonner } from "sonner";
import { TooltipProvider } from "@/components/ui/tooltip";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Routes, Route } from "react-router-dom";
import { Navigation } from "./components/Navigation";
import { OnboardingGuard } from "./components/OnboardingGuard";
import Index from "./pages/Index";
import NotFound from "./pages/NotFound";
import { Onboarding } from "./pages/Onboarding";
import Phrases from "./pages/Phrases";
import Offline from "./pages/Offline";
import StoriesCollection from "./components/StoriesCollection";

// Create a new query client instance with proper configuration
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => (
  <QueryClientProvider client={queryClient}>
    <TooltipProvider>
      <Sonner position="bottom-right" />
      <Routes>
        {/* Landing page */}
        <Route path="/" element={<Index />} />
        
        {/* App routes with navigation */}
        <Route path="/app" element={
          <OnboardingGuard>
            <>
              <Navigation />
              <StoriesCollection />
            </>
          </OnboardingGuard>
        } />
        <Route path="/onboarding" element={<Onboarding onComplete={() => {}} />} />
        <Route path="/phrases" element={
          <OnboardingGuard>
            <>
              <Navigation />
              <Phrases />
            </>
          </OnboardingGuard>
        } />
        <Route path="/offline" element={<Offline />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </TooltipProvider>
  </QueryClientProvider>
);

export default App;
