
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';

interface LoadingStateProps {
  message?: string;
  className?: string;
}

export function LoadingState({
  message = "Loading...",
  className = "",
}: LoadingStateProps) {
  return (
    <div className={`flex flex-col items-center justify-center py-12 ${className}`}>
      <FontAwesomeIcon 
        icon={faCircleArrowRight} 
        className="h-10 w-10 text-accent animate-spin mb-4" 
        spin 
      />
      <p className="text-muted-foreground">{message}</p>
    </div>
  );
}
