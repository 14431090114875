
import { TargetLanguage } from "@/types/language";
import { targetLanguages } from "@/utils/languageUtils";
import { useTranslations } from "@/hooks/useTranslations";
import { 
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue, 
} from "@/components/ui/select";
import * as CustomIcons from '@/lib/customIcons';

interface TargetLanguageSelectorProps {
  selectedLanguage: TargetLanguage;
  onSelectLanguage: (language: TargetLanguage) => void;
  label: string;
  disabledLanguage?: TargetLanguage;
  customClass?: string;
}

export const TargetLanguageSelector = ({ 
  selectedLanguage, 
  onSelectLanguage, 
  label,
  disabledLanguage,
  customClass
}: TargetLanguageSelectorProps) => {
  const { t } = useTranslations();

  return (
    <div className="h-full">
      <Select value={selectedLanguage} onValueChange={(value) => onSelectLanguage(value as TargetLanguage)}>
        <SelectTrigger className={`w-full h-full px-4 ${customClass || "comic-select"}`}>
          <div className="flex flex-col items-start text-left">
            <span className="text-xs text-gray-500">{label}</span>
            <SelectValue placeholder={t('language.target_language')} />
          </div>
        </SelectTrigger>
        <SelectContent className="comic-select-content">
          {targetLanguages
            .sort((a, b) => t(`language.${a.id.toLowerCase()}`).localeCompare(t(`language.${b.id.toLowerCase()}`)))
            .map((language) => {
              const IconComponent = CustomIcons[language.code as keyof typeof CustomIcons];
              
              return (
                <SelectItem 
                  key={language.id} 
                  value={language.id}
                  disabled={!language.available || language.id === disabledLanguage}
                >
                  <div className="flex items-center gap-2">
                    {IconComponent && <IconComponent size="1em" />}
                    <span>{t(`language.${language.id.toLowerCase()}`)}</span>
                  </div>
                </SelectItem>
              );
            })}
        </SelectContent>
      </Select>
    </div>
  );
};
