
import { LanguageLevel } from "@/types/language";
import { 
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue 
} from "@/components/ui/select";
import { useTranslations } from "@/hooks/useTranslations";

interface LevelSelectorProps {
  selectedLevels: LanguageLevel[];
  onToggleLevel: (level: LanguageLevel) => void;
  label: string;
}

const LEVELS: LanguageLevel[] = ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'];

export const LevelSelector = ({ selectedLevels, onToggleLevel, label }: LevelSelectorProps) => {
  const { t } = useTranslations();
  
  const handleValueChange = (level: LanguageLevel) => {
    const levelOrder: LanguageLevel[] = ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'];
    const levelIndex = levelOrder.indexOf(level);
    
    // Add the selected level and all lower levels
    const newLevels = new Set<LanguageLevel>();
    for (let i = 0; i <= levelIndex; i++) {
      newLevels.add(levelOrder[i]);
    }
    
    // Update with all selected levels
    Array.from(newLevels).forEach(l => {
      onToggleLevel(l);
    });
  };

  // Get the highest selected level to show in the select
  const getCurrentLevel = () => {
    const levelOrder: LanguageLevel[] = ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'];
    return selectedLevels.reduce((highest, current) => {
      return levelOrder.indexOf(current) > levelOrder.indexOf(highest) ? current : highest;
    }, selectedLevels[0] || 'A1');
  };

  return (
    <div className="space-y-4">
      {label && <h3 className="text-sm font-medium">{label}</h3>}
      <Select value={getCurrentLevel()} onValueChange={(value) => handleValueChange(value as LanguageLevel)}>
        <SelectTrigger className="w-full comic-select">
          <SelectValue placeholder="Select your level" />
        </SelectTrigger>
        <SelectContent className="comic-select-content">
          {LEVELS.map((level) => (
            <SelectItem key={level} value={level}>
              {t(`language.level.${level}`)}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};
